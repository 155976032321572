import React, { useCallback, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { a11yFocus, isNumber } from '../../utils';
import { dispatch, getState, ItemPosition, onStoreEvent, State } from '../../store';
import { QuestionMarkIcon } from '../Icons/QuestionMarkIcon';
import {
  CHATBOT_WIDGET_ANCHOR_CONTAINER,
  CHATBOT_WIDGET_ANCHOR_WRAPPER,
  CHATBOT_WIDGET_BUTTON_CONTAINER,
} from '../../constants';
import { biDragWidget } from '../../bi';
import { WidgetTooltip } from '../WidgetTooltip';
import { Counter } from '../Counter';
import { expandWidget } from '../../sdk/methods';
import { Draggable } from '../Draggable';

import s from './styles.scss';
import { getDraggableAllowedPosition } from '../../utils/position';

export const WidgetButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<ItemPosition | null>(null);
  const buttonRef = useRef(null);
  const draggableHandleRef = useRef(null);

  const handleWidgetEvent = useCallback(
    (state: State, expanded) => {
      const visible = Boolean(!expanded && state.widgetIframeSrc);
      setIsVisible(visible);
      if (state.widget.position) {
        const { bottom, right } = state.widget.position;
        if (visible && isNumber(bottom) && isNumber(right)) {
          setPosition(state.widget.position);
        }
      }
    },
    [setIsVisible, setPosition],
  );

  useLayoutEffect(() => {
    const appRenderedListener = onStoreEvent('@events/app/render', (state) => {
      handleWidgetEvent(state, state.widget.expanded);
    });

    const widgetExpandedListener = onStoreEvent('@events/widget/expanded', (state, expanded) => {
      if (state.widget.expanded && !expanded) {
        a11yFocus(buttonRef.current);
      }
      handleWidgetEvent(state, expanded);
    });

    return () => {
      appRenderedListener();
      widgetExpandedListener();
    };
  }, [handleWidgetEvent]);

  const handleDragStart = useCallback(() => {
    const {
      chatbot: { dealerOffer },
      tooltip,
    } = getState();
    if (dealerOffer && tooltip.isOpened) {
      dispatch('tooltip/close-per-offer', dealerOffer);
    }
  }, []);

  const handleDragEnd = useCallback(({ left, top, bottom, right }) => {
    biDragWidget({ left, top, bottom, right });
    dispatch('widget/onDragEnd', { left, top, bottom, right });
  }, []);

  const { initParams } = getState();
  const allowedPosition = useMemo(() => getDraggableAllowedPosition(initParams.origin), [initParams.origin]);
  return (
    <Draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      position={position}
      allowedPosition={allowedPosition}
      draggableHandleRef={draggableHandleRef}
      className={s.buttonContainer}
      data-hook={CHATBOT_WIDGET_BUTTON_CONTAINER}
      aria-hidden={!isVisible}
      style={{ display: isVisible ? 'flex' : 'none' }}
    >
      <div className={s.anchorWrapper} data-hook={CHATBOT_WIDGET_ANCHOR_WRAPPER}>
        <button
          onClick={expandWidget}
          type="button"
          className={s.anchorContainer}
          aria-label="Need Help?"
          data-hook={CHATBOT_WIDGET_ANCHOR_CONTAINER}
          ref={buttonRef}
        >
          <QuestionMarkIcon />
        </button>
        <Counter />
        {!initParams.isInvokedFromHeader && <WidgetTooltip />}
        <div ref={draggableHandleRef} className={s.draggableHandle} />
      </div>
    </Draggable>
  );
};
