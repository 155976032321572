import classnames from 'classnames';
import {
  HELP_WIDGET,
  WIDGET_DEFAULT_EMBED_CLASS,
  WIDGET_HELP_CENTER_THEME_CLASS,
  WIDGET_PLACEHOLDER_EMBED_CLASS,
} from '../../constants';
import { getState } from '../../store';

// import global styles
import '../../styles/styles.scss';
// import wrapper styles
import styles from './styles.scss';

import React from 'react';
import { widgetModel } from '../../widgetModel';

export const WidgetWrapper: React.FC = ({ children }) => {
  const brand = widgetModel.getBrand();
  const {
    initParams: { placeholder },
  } = getState();

  const embedClassName = placeholder?.element ? WIDGET_PLACEHOLDER_EMBED_CLASS : WIDGET_DEFAULT_EMBED_CLASS;

  return (
    <div
      data-hook={HELP_WIDGET}
      className={classnames(embedClassName, WIDGET_HELP_CENTER_THEME_CLASS, {
        [styles.widgetWrapperWixStudio]: brand === 'WixStudio',
      })}
    >
      {children}
    </div>
  );
};
