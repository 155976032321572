import { IframeQueryParameters } from '@wix/wix-chatbot-common/browser';
import type { ActiveChatSession, InitParams } from '@wix/wix-help-widget-common/types';

import { DealerOfferDetails, isWorkingHours } from '../dealer';
import { BASE_URL, WIDGET_URL, WIDGET_HOST } from '../constants';
import { SessionService } from '../services';
import { hwPublishLog, LOG_EVENTS } from '../events/publish/widgetChannel';
import { getState } from '../store';
import { isNonNullable } from '../utils';
import { createUrlWithClientRoute, clientRoutes, PredefinedClientRoute } from './clientRoutes';
import { widgetModel } from '../widgetModel';
import { OriginApp, SupportChatOrigins } from '@wix/wix-help-widget-common/types';

type QueryParams = Record<string, string | boolean | undefined | null>;

const getWixRoute = (): string | null => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('x-wix-route');
};

export const whitelistUrlParams = ['nodeId', 'ni', 'versionId', 'vi', 'collectionsData'];

export const buildWidgetUrl = (
  url: string,
  queryParams: QueryParams = {},
  predefinedClientRoute?: PredefinedClientRoute,
): string => {
  let createdUrl: URL;
  try {
    createdUrl = new URL(url);
  } catch (e) {
    const errorMessage = `buildWidgetUrl: could not parse URL "${url}": ${e.message}`;

    console.error(errorMessage);

    hwPublishLog(LOG_EVENTS.BUILD_WIDGET_URL_ERROR, {
      errorMessage,
    });

    throw e;
  }
  const brand = widgetModel.getBrand();
  const { placeholder, isInvokedFromHeader, origin } = getState().initParams;
  const isPlaceholderEmbed = !!placeholder?.element;

  const params = {
    loadPageUrl: window.location.href,
    [IframeQueryParameters.provideRuntimeDataFromSDK]: true,
    isInvokedFromHeader: !!isInvokedFromHeader,
    brand,
    origin,
    'x-wix-route': getWixRoute(),
    ...(isPlaceholderEmbed && { fullWidth: true }),
    ...queryParams,
  };
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (isNonNullable(paramValue)) {
      createdUrl.searchParams.set(paramName, paramValue.toString());
    }
  });

  if (predefinedClientRoute) {
    createdUrl = createUrlWithClientRoute[predefinedClientRoute.route](createdUrl, predefinedClientRoute);
    createdUrl.searchParams.set(IframeQueryParameters.applyUrlToRouter, 'true');
  }

  return createdUrl.href;
};

const getStartUrlFromSession = (
  session?: ActiveChatSession,
  predefinedClientRoute?: PredefinedClientRoute,
): string => {
  return SessionService.isSessionActive(session)
    ? buildWidgetUrl(`${WIDGET_URL}${session?.startUrl || ''}`, undefined, predefinedClientRoute)
    : '';
};

export const getStartUrlFromDealerOffer = ({
  dealerOffer,
  queryParams,
  predefinedClientRoute,
}: {
  dealerOffer?: DealerOfferDetails;
  queryParams?: QueryParams;
  predefinedClientRoute?: PredefinedClientRoute;
}): string => {
  const dealerPayload = dealerOffer?.payload;

  if (dealerPayload?.chatbotConfig.value && isWorkingHours(dealerPayload)) {
    const dealerUrl = dealerPayload.chatbotConfig.value;

    try {
      const url = new URL(dealerUrl, BASE_URL);
      const params = {
        interactionId: dealerPayload.interactionId,
        ...queryParams,
      };
      url.host = WIDGET_HOST;
      return buildWidgetUrl(url.href, params, predefinedClientRoute);
    } catch (e) {
      const errorMessage = `getStartUrlFromDealerOffer: could not parse URL: "${dealerUrl}": ${e.message}`;

      console.error(errorMessage);

      hwPublishLog(LOG_EVENTS.GET_START_URL_FROM_OFFER_PARSE_ERROR, {
        dealerUrl,
        errorMessage,
      });
    }
  }

  return '';
};

const getStartUrlFromQueryParams = (predefinedClientRoute?: PredefinedClientRoute) => {
  const searchParams = new URLSearchParams(window.location.search);

  // if there's at least 1 param from the whitelist, we treat it as deep link
  if (whitelistUrlParams.some((key) => searchParams.has(key))) {
    return buildWidgetUrl(`${WIDGET_URL}?${searchParams}`, undefined, predefinedClientRoute);
  }

  return '';
};

type GetStartUrl = (activeSession?: ActiveChatSession, dealerOffer?: DealerOfferDetails) => string;

const getWidgetStartUrl: GetStartUrl = (activeSession, dealerOffer) => {
  return (
    getStartUrlFromQueryParams() ||
    getStartUrlFromSession(activeSession) ||
    getStartUrlFromDealerOffer({ dealerOffer })
  );
};

export const getStartUrl = (
  initParams: InitParams,
  activeSession?: ActiveChatSession,
  dealerOffer?: DealerOfferDetails,
): string => {
  const { testMode } = initParams;
  const defaultStartUrl = testMode ? buildWidgetUrl(WIDGET_URL) : '';
  const startUrl = getWidgetStartUrl(activeSession, dealerOffer);
  return startUrl || defaultStartUrl;
};

export function getHelpCenterUrl(articleId?: string, articleAnchor?: string): string {
  if (articleId) {
    const clientRoute = clientRoutes.articleById(articleId, articleAnchor);
    return buildWidgetUrl(WIDGET_URL, {}, clientRoute);
  } else {
    return buildWidgetUrl(WIDGET_URL);
  }
}

export const buildHelpWidgetStartUrl = (
  origin: SupportChatOrigins,
  activeSession?: ActiveChatSession,
): string => {
  const url = getStartUrlFromQueryParams() || getStartUrlFromSession(activeSession);
  if (url) {
    return url;
  }
  if (origin === OriginApp.Editor) {
    return '';
  }
  return buildWidgetUrl(WIDGET_URL);
};
