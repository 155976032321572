import type { HelpWidgetSession } from '@wix/wix-help-widget-common/types';
import { StoreonModule } from 'storeon';
import { Events, State, ItemPosition } from '..';
import {
  widgetExpandedMiddleware,
  widgetCurrentUrlMiddleware,
  setWidgetSizeMiddleware,
} from '../middlewares/widgetMiddleware';
import { getPositionFromOffer } from '../../dealer';
import { EditorLink, WidgetSize } from '@wix/wix-chatbot-common/browser';
import { assertUnreachable } from '../../utils';

export enum InitialWidgetPosition {
  Center,
}

export type WidgetState = {
  widget: {
    expanded: boolean;
    rendered: boolean;
    size: WidgetSize;
    initialPosition?: InitialWidgetPosition;
    position?: ItemPosition;
    currentUrl?: string;
  };
};

export type WidgetEvents = {
  'widget/expanded': boolean;
  'widget/rendered': boolean;
  'widget/setWidgetSize': WidgetSize;
  'widget/setInitialPosition': InitialWidgetPosition;
  'widget/position': ItemPosition;
  'widget/onDragEnd': ItemPosition;
  'widget/currentUrl': string;
  'widget/setSession': HelpWidgetSession;
  'widget/editorLinkCallback': EditorLink;

  '@events/widget/expanded': boolean;
  '@events/widget/iframeSrcSet': undefined;
  '@events/widget/iframeLoaded': undefined;
};

export const widgetModule: StoreonModule<State, Events> = ({ on, dispatch }) => {
  on('@init', () => ({
    widget: {
      expanded: false,
      rendered: false,
      size: WidgetSize.Default,
    },
  }));

  on('widget/expanded', (state, expanded) => {
    widgetExpandedMiddleware({ dispatch, state, expanded });
    return {
      widget: {
        ...state.widget,
        expanded,
        ...(!expanded && {
          // reset initial position when widget is closed
          // initialPosition should be applied only when widget is opening(next time widget is open it should be default)
          // example: when widget is open from editor GFPP we send specific parameter to open widget in the center of the page,
          // but when widget(opened from GFPP) is closed and then opened from Header, we need to apply default position under "Help" button
          initialPosition: undefined,
        }),
      },
      // write to data capsule only if expanded value has changed
      ...(state.widget.expanded !== expanded && {
        __dataCapsule: Date.now(),
      }),
    };
  });

  on('widget/rendered', ({ widget }, rendered) => ({
    widget: {
      ...widget,
      rendered,
    },
  }));

  on('widget/onDragEnd', (oldState, position) => ({
    widget: {
      ...oldState.widget,
      position,
    },
    __dataCapsule: Date.now(),
  }));

  on('chatbot/setDealerOffer', (state, dealerOffer) => {
    if (state.initParams.isInvokedFromHeader) {
      return state;
    }
    const position = getPositionFromOffer(dealerOffer);
    if (!position) {
      return state;
    }
    return {
      widget: {
        ...state.widget,
        position,
      },
    };
  });

  on('widget/currentUrl', (state, currentUrl) => {
    widgetCurrentUrlMiddleware({ currentUrl, state });
    return {
      widget: {
        ...state.widget,
        currentUrl,
      },
    };
  });

  on('widget/setSession', (state, widgetSession) => {
    return updateWidgetSession(state, widgetSession);
  });

  on('widget/setWidgetSize', (state, size) => {
    setWidgetSizeMiddleware({ size, state });
    return {
      widget: {
        ...state.widget,
        size,
      },
    };
  });

  on('widget/setInitialPosition', (state, initialPosition) => {
    return {
      widget: {
        ...state.widget,
        initialPosition,
      },
    };
  });

  on('app/setInitialData', (state, { widgetSession }) => {
    return updateWidgetSession(state, widgetSession);
  });

  on('widget/editorLinkCallback', (state, editorLinkCallback: EditorLink) => {
    switch (editorLinkCallback) {
      case EditorLink.TourTheEditor:
        state.initParams?.editorCallbacks?.tourTheEditorCallback?.();
        break;
      case EditorLink.NewReleases:
        state.initParams?.editorCallbacks?.newReleasesCallback?.();
        break;
      default:
        assertUnreachable(editorLinkCallback);
    }
  });
};

const updateWidgetSession = (state: State, widgetSession?: HelpWidgetSession): WidgetState | void => {
  // do not restore widget state if it's "from-the-header"(it can be saved if user opens landing page with chatbot widget)
  if (!widgetSession?.widgetData || state.initParams.isInvokedFromHeader) {
    return;
  }
  const { widget } = widgetSession.widgetData;
  const {
    widget: { expanded, position },
  } = state;
  return {
    widget: {
      ...state.widget,
      expanded: widget?.expanded ?? expanded,
      position: widget?.position ?? position,
    },
  };
};
