import React, { MutableRefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { CHATBOT_WIDGET_IFRAME, iframeElementId } from '../../constants';
import { dispatch, onStoreEvent, State } from '../../store';
import { fedopsLogger } from '../../fedops';

import styles from './iframe.scss';
import classnames from 'classnames';

export const WidgetIframe = React.forwardRef<HTMLIFrameElement>((_, ref) => {
  const internalRef = useRef<HTMLIFrameElement | null>(null);
  const iframeRef = (ref || internalRef) as MutableRefObject<HTMLIFrameElement | null>;
  const [iframeStartUrl, setIframeStartUrl] = useState<string | undefined>();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleWidgetEvent = useCallback(
    (state: State, expanded) => {
      if (iframeRef.current?.src && state.widget.rendered) {
        return;
      }
      if (!state.chatbot.isChatbotSessionActive && !expanded) {
        return;
      }
      fedopsLogger.interactionStarted('client-app-loaded');
      setIframeStartUrl(state.widgetIframeSrc);
      dispatch('@events/widget/iframeSrcSet');
    },
    [iframeRef],
  );

  useLayoutEffect(() => {
    const appRenderedListener = onStoreEvent('@events/app/render', (state) => {
      handleWidgetEvent(state, state.widget.expanded);
    });
    const widgetExpandedListener = onStoreEvent('@events/widget/expanded', handleWidgetEvent);

    return () => {
      appRenderedListener();
      widgetExpandedListener();
    };
  }, [handleWidgetEvent]);

  const onLoadHandler = useCallback(() => {
    dispatch('@events/widget/iframeLoaded');
    setIsLoaded(true);
  }, []);

  return (
    <iframe
      onLoad={onLoadHandler}
      ref={iframeRef}
      src={iframeStartUrl}
      id={iframeElementId}
      title="Wixbot"
      className={classnames(styles.iframe, { [styles.hidden]: !isLoaded })}
      data-hook={CHATBOT_WIDGET_IFRAME}
      allow="clipboard-write"
    />
  );
});
