// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ix6DpF{width:16px;height:26px;color:#fff}.fcK20T{width:10px;height:10px}.qJNe76{border:2px solid #fff;border-radius:50%;background-color:#fff}.V4ErA2{color:#868aa5}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question-mark": `ix6DpF`,
	"questionMark": `ix6DpF`,
	"close-button": `fcK20T`,
	"closeButton": `fcK20T`,
	"tooltip-icon": `qJNe76`,
	"tooltipIcon": `qJNe76`,
	"info-icon": `V4ErA2`,
	"infoIcon": `V4ErA2`
};
export default ___CSS_LOADER_EXPORT___;
