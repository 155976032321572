import { InitParams } from '@wix/wix-help-widget-common/types';

import { widgetModel } from '../widgetModel';
import { biWidgetLoadFailure } from '../bi';
import { WIDGET_LOAD_FAIL_PHASE } from '../types';
import { dispatch } from '../store';

export const isWidgetDisabled = (initParams: InitParams) => {
  if (!initParams || !initParams.origin) {
    throw new Error('Must pass an origin');
  }
  const experiments = widgetModel.getExperiments();
  return [
    experiments.enabled('specs.chatbot.SdkInMaintenanceAllUsers'),
    experiments.enabled('specs.support.LessCapabilitiesForSupportUser'),
  ].some(Boolean);
};

export const onWidgetFailure = (initParams: InitParams, error: Error) => {
  biWidgetLoadFailure({
    failReason: (error as any).failReason || WIDGET_LOAD_FAIL_PHASE.STATE_LOADING,
    failDetails: error.message,
    initParams,
  });

  dispatch('app/setHasFatalError', true);
  console.error('runWidgetApp', error.message);
};
