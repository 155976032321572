import type { UserContext } from '@wix/wix-chatbot-common/browser';
import type { CareProfile, UserExperience } from '@wix/wix-help-widget-common/personalization';

import type { StoreonModule } from 'storeon';
import type { State, Events } from '..';

export type ContextState = {
  context: {
    user?: UserContext;
  };
};

export type ContextEvents = {
  'context/setUserExperience'?: UserExperience;
  'context/setCareProfile'?: CareProfile;
};

export const contextModule: StoreonModule<State, Events> = ({ on }) => {
  on('@init', () => ({
    context: {},
  }));

  on('context/setUserExperience', ({ context }, userExperience) => {
    return {
      context: {
        ...context,
        user: {
          ...context.user,
          userExperience,
        },
      },
    };
  });

  on('context/setCareProfile', ({ context }, careProfile) => {
    return {
      context: {
        ...context,
        user: {
          ...context.user,
          careProfile,
        },
      },
    };
  });

  on('app/setInitialData', ({ context }, action) => {
    return {
      context: {
        ...context,
        user: action.userContext,
      },
    };
  });
};
