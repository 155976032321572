import {
  WIDGET_SUPPORT_CHATBOT,
  WIDGET_SDK_CHANNEL,
  WidgetEvents,
  WixSupportChatSDKInterface,
  WidgetInternalSDKMethods,
} from '@wix/wix-chatbot-common/browser';
import { dispatch } from '../../store';
import {
  hwPublishCallInternalHandlerResponse,
  hwPublishCallSdkMethodResponse,
} from '../publish/widgetChannel';
import { WidgetSDKHandler } from '../WidgetSDKHandler';

const isBlocked = ({ origin, data }: MessageEvent): boolean => {
  if (process.env.NODE_ENV === 'development') {
    return false;
  }
  const validDataType = [WIDGET_SUPPORT_CHATBOT, WIDGET_SDK_CHANNEL].includes(data?.type);
  const currentOrigin = location.origin === origin;
  return !validDataType || !currentOrigin;
};

const postMessageHandler = (widgetSDKHandler: WidgetSDKHandler) => {
  return async (messageEvent: MessageEvent): Promise<void> => {
    if (isBlocked(messageEvent)) {
      return;
    }

    const { event, payload } = messageEvent.data?.data || {};

    if (event === WidgetEvents.CALL_SDK_METHOD_REQUEST) {
      const { methodName, methodParams, requestId } = payload;
      const result = await window.WixSupportChatSDK[methodName as keyof WixSupportChatSDKInterface](
        methodParams,
      );

      hwPublishCallSdkMethodResponse({
        requestId,
        methodName,
        methodParams,
        result,
      });
    }

    if (event === WidgetEvents.CALL_INTERNAL_HANDLER_REQUEST) {
      const { methodName, methodParams, requestId } = payload;
      const result = await widgetSDKHandler[methodName as keyof WidgetInternalSDKMethods](methodParams);

      hwPublishCallInternalHandlerResponse({
        requestId,
        methodName,
        methodParams,
        result,
      });
    }

    if (event === WidgetEvents.UPDATE_WIDGET_URL) {
      dispatch('widget/currentUrl', payload.url);
    }
  };
};

export const subscribeToPostMessage = (widgetSDKHandler: WidgetSDKHandler): void => {
  window.addEventListener('message', postMessageHandler(widgetSDKHandler));
};
