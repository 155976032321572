import { useTabActive } from '@wix/wix-chatbot-common/hooks';
import { useEffect } from 'react';
import { getState } from '../store';
import { markAsRead } from '../api/messageApi';
import { shouldClearNotifications } from '../duplexer/notificationsHelper';

export const useMarkAsReadOnTabChange = () => {
  const isTabActive = useTabActive();

  const markMessagesAsRead = (): void => {
    const { conversationId, lastMessageId, unreadCount } = getState().chatbot.conversation;
    if (unreadCount === 0) {
      return;
    }
    markAsRead(conversationId, lastMessageId);
  };

  useEffect(() => {
    if (isTabActive) {
      // Mark msg as read in case the user switched to the chatbot page in the widget (should not happen since
      // widget should be aligned in all tabs)
      const { widget } = getState();
      if (shouldClearNotifications(widget, false /* isNewConversation */, isTabActive)) {
        markMessagesAsRead();
      }
    }
  }, [isTabActive]);
};
