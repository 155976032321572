import EventEmitter from 'events';
import { InitParams } from '@wix/wix-help-widget-common/types';
import {
  WidgetStateListener,
  WixSupportChatSDKInterface,
  HelpCenterParams,
  NavigationParams,
  NavigationCompleted,
  TOOLTIP_CLOSE_TIMEOUT,
  OpenHelpWidgetArticleParams,
  OpenHelpWidgetChatbotParams,
  OpenHelpWidgetSearchParams,
} from '@wix/wix-chatbot-common/browser';

import { runWidgetApp } from './runApp';
import { fedopsLogger } from './fedops';
import * as sdkMethods from './sdk/methods';
import { getHelpCenterUrl } from './widget/startUrl';
import { WidgetClosingTrigger } from '@wix/wix-chatbot-common/bi';
import { WidgetSdkEventEmitter } from './types';
import type { EnvSpecificSdkMethods } from './sdk/methods';

export class WixSupportChatSDK
  extends (EventEmitter as new () => WidgetSdkEventEmitter)
  implements WixSupportChatSDKInterface
{
  // Used in Editor segment. Can be removed after moving to header
  answersScriptLoaded = Promise.resolve();

  private readonly _initCompleted = new Promise<void>((resolve) => {
    this.once('initComplete', () => resolve());
  });

  constructor() {
    // eslint-disable-next-line constructor-super
    super();
    fedopsLogger.appLoadStarted();
    // widget is loaded just once
    this.once('appRenderComplete', () => {
      fedopsLogger.appLoaded();
    });
  }

  set envMethods({ navigateTo }: EnvSpecificSdkMethods) {
    this.navigateTo = navigateTo;
  }

  async init(initParams: InitParams): Promise<void> {
    this.envMethods = await sdkMethods.createEnvSpecificMethods(initParams.origin);

    await runWidgetApp({
      tooltipCloseTimeout: TOOLTIP_CLOSE_TIMEOUT,
      ...initParams,
    });
    this.emit('initComplete');
  }

  openHelpWidgetHomePage(): void {
    this._initCompleted.then(() => sdkMethods.openHelpWidgetHomePage());
  }

  openHelpWidgetArticle(params: OpenHelpWidgetArticleParams): void {
    this._initCompleted.then(() => sdkMethods.openHelpWidgetArticle(params));
  }

  openHelpWidgetChatbot(params: OpenHelpWidgetChatbotParams): void {
    this._initCompleted.then(() => sdkMethods.openHelpWidgetChatbot(params));
  }

  openHelpWidgetSearch(params: OpenHelpWidgetSearchParams): void {
    this._initCompleted.then(() => sdkMethods.openHelpWidgetSearch(params));
  }

  getHelpCenterUrl(params: HelpCenterParams): string {
    return getHelpCenterUrl(params.articleId, params.articleAnchor);
  }

  navigateTo(_: NavigationParams): Promise<NavigationCompleted> | void {
    throw new Error('navigateTo implementation not provided');
  }

  collapseWidget(closingTrigger: WidgetClosingTrigger) {
    sdkMethods.collapseWidget(closingTrigger);
  }

  expandWidget() {
    sdkMethods.expandWidget();
  }

  isWidgetExpanded() {
    return sdkMethods.isWidgetExpanded();
  }

  addWidgetStateListener(callback: WidgetStateListener) {
    this.on('updateWidgetState', callback);
  }

  removeWidgetStateListener(callback: WidgetStateListener) {
    this.off('updateWidgetState', callback);
  }

  _init = this.init;
}
