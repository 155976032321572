import { dispatch, getState } from '../../store';
import { canShow } from './canShow';
import { TooltipType } from '../../types';

let timeout: number | undefined;

export const scheduleOpen = (seconds: number): void => {
  timeout = window.setTimeout(() => {
    const {
      initParams: { tooltipCloseTimeout },
      widget: { expanded },
      tooltip,
      chatbot: { dealerOffer, isChatbotSessionActive },
      widgetIframeSrc,
    } = getState();

    if (
      canShow({
        expanded,
        tooltip,
        isChatbotSessionActive,
        startUrl: widgetIframeSrc,
        tooltipCloseTimeout,
        dealerOfferGuid: dealerOffer?.guid,
        tooltipType: TooltipType.DealerOffer,
      })
    ) {
      dispatch('tooltip/open');
    }
  }, seconds * 1000);
};

export const cancelSchedule = (): void => {
  if (timeout) {
    window.clearTimeout(timeout);
    timeout = undefined;
  }
};
