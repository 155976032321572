import { isClosed } from './isClosed';
import { TooltipType } from '../../types';
import { TooltipState } from '../../store/modules/tooltip';

export type CanShowParams = {
  expanded: boolean;
  tooltip: TooltipState;
  isChatbotSessionActive: boolean;
  startUrl: string;
  tooltipCloseTimeout?: number;
  dealerOfferGuid?: string;
  tooltipType?: TooltipType;
};

export const canShow = ({
  expanded,
  tooltip,
  isChatbotSessionActive,
  startUrl,
  tooltipCloseTimeout = 0,
  dealerOfferGuid,
  tooltipType,
}: CanShowParams): boolean => {
  return (
    !isChatbotSessionActive &&
    !isClosed({ tooltipCloseTimeout, tooltip, dealerOfferGuid, tooltipType }) &&
    !expanded &&
    !!startUrl
  );
};
