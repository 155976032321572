import type { HelpWidgetSession, WidgetData } from '@wix/wix-help-widget-common/types';

import { WIDGET_SESSION_URL } from '../constants';
import { httpClient } from '../utils';
import { UpdateWidgetSessionResponse } from '../types';

export const updateWidgetSession = (data: WidgetData): Promise<UpdateWidgetSessionResponse> => {
  return httpClient
    .getInstance()
    .put(WIDGET_SESSION_URL, { widgetData: { ...data, lastUpdated: new Date() } })
    .then((res) => res.data)
    .catch((error) => {
      console.error('updateWidgetSessionData', error);
    });
};

export const getWidgetSession = (): Promise<HelpWidgetSession> => {
  return httpClient
    .getInstance()
    .get(WIDGET_SESSION_URL)
    .then((res) => res.data)
    .catch((error) => {
      console.error('getWidgetSessionData', error);
      return {};
    });
}
