import { NavigationParams, NavigationByUrl } from '@wix/wix-chatbot-common/browser';

export const isUrlNavigationParameters = (params: NavigationParams): params is NavigationByUrl =>
  'url' in params;

export const navigateTo = (params: NavigationParams): void => {
  try {
    if (!isUrlNavigationParameters(params)) {
      return;
    }

    if (!isValidURL(params.url)) {
      return;
    }

    if (params.openInNewTab) {
      window.open(params.url, '_blank');
    } else {
      window.location.href = params.url;
    }
  } catch (error) {}
};

function isValidURL(urlToCheck: string): boolean {
  let url: URL;
  try {
    url = new URL(urlToCheck);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
