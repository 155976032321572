import type { ActiveChatSession } from '@wix/wix-help-widget-common/types';

import { FIVE_MINUTES_IN_SECONDS } from '../constants';
import { isNumber } from '../utils';

export class SessionService {
  static isSessionActive(activeSession?: ActiveChatSession): boolean {
    return Boolean(
      activeSession &&
        isNumber(activeSession.secondsSinceLastActivity) &&
        activeSession.secondsSinceLastActivity < FIVE_MINUTES_IN_SECONDS,
    );
  }
}
