import { SupportChatOrigins } from '@wix/wix-help-widget-common/types';
import { NavigationCompleted, NavigationParams } from '@wix/wix-chatbot-common/browser';
import { navigateTo } from './navigation';

export interface EnvSpecificSdkMethods {
  navigateTo: (parameters: NavigationParams) => Promise<NavigationCompleted> | void;
}
/**
 * get WixSupportChatSDK methods specific for different environment
 * for example navigateTo can have different implementation in the BM, Editor, landing pages
 */
export const createEnvSpecificMethods = async (
  origin: SupportChatOrigins,
): Promise<EnvSpecificSdkMethods> => {
  if (origin === 'BusinessManager') {
    return import('./BusinessManager');
  }

  return { navigateTo };
};
