import { dispatch, getState } from '../../store';
import { biOpenWidget } from '../../bi';

export const expandWidget = (): void => {
  const {
    chatbot: { dealerOffer },
    tooltip,
  } = getState();
  dispatch('widget/expanded', true);
  dispatch('chatbot/unreadCount', 0);
  biOpenWidget();
  if (dealerOffer && tooltip.isOpened) {
    dispatch('tooltip/close-per-offer', dealerOffer);
  }
};
