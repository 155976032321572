import { contextProvider, Context } from '@wix/wix-chatbot-common/browser';

import { getState } from '../store';
import { isMobile } from './index';

export const getContext = (): Promise<Context> => {
  const {
    context: { user },
  } = getState();

  return contextProvider.getContext({
    ...(user ? user : {}),
    isMobile: isMobile(),
    isWidget: true,
  });
};
