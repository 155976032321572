import { OpenHelpWidgetChatbotParams } from '@wix/wix-chatbot-common/browser';
import { WIDGET_URL } from '../constants';
import { hwPublishNewDealerOfferParams, publishOpenChatbotPage } from '../events/publish/widgetChannel';
import { dispatch, getState } from '../store';
import { buildWidgetUrl } from '../widget/startUrl';
import { clientRoutes } from '../widget/clientRoutes';

export const openChatbotPage = (params: OpenHelpWidgetChatbotParams = {}) => {
  const {
    widgetIframeSrc,
    chatbot: { isChatbotSessionActive },
  } = getState();
  const validatedParams = Object.fromEntries(
    Object.entries(params).filter(([key]) => key === 'nodeId' || key === 'referral' || key.startsWith('_')),
  );

  const clientRoute = clientRoutes.chatbot();
  const newUrl = isChatbotSessionActive
    ? buildWidgetUrl(widgetIframeSrc ? widgetIframeSrc : WIDGET_URL, undefined, clientRoute)
    : buildWidgetUrl(WIDGET_URL, validatedParams, clientRoute);

  const { searchParams } = new URL(newUrl);
  const dealerOfferParams = new Map(searchParams.entries());
  publishOpenChatbotPage();
  hwPublishNewDealerOfferParams(dealerOfferParams);
  dispatch('app/setWidgetIframeSrc', newUrl);
};
