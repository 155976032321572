import React from 'react';
import { Box, Loader as WSRLoader } from '@wix/design-system';

export const Loader: React.FC = () => {
  return (
    <Box align="center" height="100%" width="100%" verticalAlign="middle">
      <WSRLoader />
    </Box>
  );
};
