import { onPopState, onPushState, onReplaceState } from './historyTracker';
import { debounce, Callback } from '../../utils/debounce';

const focusInInputField = (): boolean => {
  const { activeElement } = document;

  if (!activeElement) {
    return false;
  }

  const buttonInputs = ['submit', 'reset', 'button'];
  const tagName = activeElement.tagName.toLowerCase();
  const type = (activeElement.getAttribute('type') || '').toLowerCase();

  if (!['input', 'textarea'].includes(tagName)) {
    return false;
  }

  return !buttonInputs.includes(type);
};

export const subscribeToNavigation = (cb: Callback): void => {
  const handleNavigation = debounce(() => {
    // only trigger navigation callback when the user is not typing/interacting with input fields
    if (!focusInInputField()) {
      cb();
    }
  });

  onPushState(handleNavigation);
  onReplaceState(handleNavigation);
  onPopState(handleNavigation);
};
