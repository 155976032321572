import { OpenHelpWidgetArticleParams } from '@wix/wix-chatbot-common/browser';
import { isString } from '../../utils';
import { WIDGET_URL } from '../../constants';
import { dispatch, getState } from '../../store';
import { publishNewArticleUri } from '../../events/publish/widgetChannel';
import { buildWidgetUrl } from '../../widget/startUrl';
import { clientRoutes } from '../../widget/clientRoutes';
import { openWidget } from './openWidget';

export function openHelpWidgetArticle({ articleUri }: OpenHelpWidgetArticleParams) {
  if (!isString(articleUri)) {
    throw new Error('Invalid articleUri');
  }

  const { widgetIframeSrc } = getState();
  // if no dealer offer and no dealer session old url will be empty string
  const currentUrl = widgetIframeSrc ? widgetIframeSrc : WIDGET_URL;
  const clientRoute = clientRoutes.articleByUri(articleUri);
  const newUrl = buildWidgetUrl(currentUrl, undefined, clientRoute);

  publishNewArticleUri(articleUri);
  dispatch('app/setWidgetIframeSrc', newUrl);

  openWidget();
}
