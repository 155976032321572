export enum SupportedClientRoute {
  ArticleByUri,
  ArticleById,
  Chatbot,
  Search,
}

type PredefinedClientChatbot = Readonly<{
  route: SupportedClientRoute.Chatbot;
}>;

type PredefinedClientSearch = Readonly<{
  route: SupportedClientRoute.Search;
  query: string;
}>;

type ArticleRouteParams = Readonly<{
  article: string;
  anchor?: string;
}>;

type PredefinedClientArticleByUri = ArticleRouteParams &
  Readonly<{
    route: SupportedClientRoute.ArticleByUri;
  }>;

type PredefinedClientArticleById = ArticleRouteParams &
  Readonly<{
    route: SupportedClientRoute.ArticleById;
  }>;

export type PredefinedClientRoute =
  | PredefinedClientChatbot
  | PredefinedClientSearch
  | PredefinedClientArticleByUri
  | PredefinedClientArticleById;

export const clientRoutes = {
  chatbot: (): PredefinedClientChatbot => ({
    route: SupportedClientRoute.Chatbot,
  }),
  search: (query: string): PredefinedClientSearch => ({
    route: SupportedClientRoute.Search,
    query,
  }),
  articleById: (article: string, anchor?: string): PredefinedClientArticleById => ({
    route: SupportedClientRoute.ArticleById,
    article,
    anchor,
  }),
  articleByUri: (article: string, anchor?: string): PredefinedClientArticleByUri => ({
    route: SupportedClientRoute.ArticleByUri,
    article,
    anchor,
  }),
};

type CreateUrlWithClientRoute = (url: URL, predefinedClientRoute: PredefinedClientRoute) => URL;

export const createUrlWithClientRoute: Record<SupportedClientRoute, CreateUrlWithClientRoute> = {
  [SupportedClientRoute.Chatbot]: getCreateUrlWithChatbotRoute(),
  [SupportedClientRoute.Search]: getCreateUrlWithSearchRoute(),
  [SupportedClientRoute.ArticleById]: getCreateUrlWithArticleRoute(true),
  [SupportedClientRoute.ArticleByUri]: getCreateUrlWithArticleRoute(false),
};

function getCreateUrlWithChatbotRoute(): CreateUrlWithClientRoute {
  return (url) => {
    const newUrl = new URL(url);
    newUrl.pathname = '/contact/widget/chatbot';
    return newUrl;
  };
}

function getCreateUrlWithSearchRoute(): CreateUrlWithClientRoute {
  return (url, route) => {
    const newUrl = new URL(url);
    const { query } = route as PredefinedClientSearch;
    newUrl.pathname = `/contact/widget/search`;
    newUrl.searchParams.set('query', query);
    return newUrl;
  };
}

function getCreateUrlWithArticleRoute(articleById: boolean): CreateUrlWithClientRoute {
  return (url, route) => {
    const newUrl = new URL(url);
    const { article, anchor } = route as PredefinedClientArticleById | PredefinedClientArticleByUri;

    newUrl.pathname = '/contact/widget/article';
    newUrl.pathname += articleById ? `/id/${article}` : `/uri/${article}`;
    newUrl.hash = anchor ?? '';

    return newUrl;
  };
}
