import type { DealerOfferDetails } from '../types';
import type { TooltipRenderDataWithTimeout } from '../../types';

export const getTooltipFromOffer = (offer?: DealerOfferDetails): TooltipRenderDataWithTimeout | undefined => {
  if (!offer) {
    return;
  }

  const { tooltipAppearanceAfterXSeconds, tooltipContent, tooltipTitle, tooltipCta, tooltipIntent } =
    offer.payload;

  return tooltipAppearanceAfterXSeconds && tooltipTitle
    ? {
        appearanceAfterXSeconds: tooltipAppearanceAfterXSeconds,
        content: tooltipContent,
        title: tooltipTitle,
        cta: tooltipCta,
        intent: tooltipIntent,
      }
    : undefined;
};
