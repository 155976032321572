import { HttpClient } from '@wix/http-client';

const headers: { [key: string]: any } = {};

const wdp = new URL(window.location.href).searchParams.get('x-wix-route');
const [artifact, wdpId] = wdp ? wdp.split(':') : [];
if (artifact && wdpId) {
  headers[`x-wix-route-${artifact}`] = wdpId;
}

class HttpClientClass {
  private httpClientInstance?: HttpClient;

  init(authToken?: string) {
    if (!this.httpClientInstance) {
      this.httpClientInstance = new HttpClient({
        headers,
        getAppToken: () => authToken || '',
      });
    }
    return this.httpClientInstance;
  }

  getInstance() {
    if (!this.httpClientInstance) {
      throw new Error('HttpClient must be initialize first');
    }
    return this.httpClientInstance;
  }

  setAuthToken(authToken: string) {
    this.httpClientInstance = new HttpClient({
      headers: { ...headers, Authorization: authToken },
      getAppToken: () => authToken,
    });
  }
}

export const httpClient = new HttpClientClass();
