import { contextProvider } from '@wix/wix-chatbot-common/browser';

import { assertExists, httpClient } from '../utils';
import type { DataFetcher } from './types';
import type { InitWidgetResponse } from '../types';
import { fedopsLogger } from '../fedops';
import { WidgetDataRequestFailure } from '../errors';
import { WIDGET_INIT_URL } from '../constants';
import { widgetModel } from '../widgetModel';
import { dispatch } from '../store';

export class InitWidgetService implements DataFetcher<InitWidgetResponse> {
  private initialWidgetData: InitWidgetResponse;

  async get(): Promise<InitWidgetResponse | undefined> {
    if (this.initialWidgetData === undefined) {
      await this.fetch();
    }
    assertExists(this.initialWidgetData, 'InitialWidgetData should be defined after load');
    return this.initialWidgetData;
  }

  async fetch(): Promise<InitWidgetResponse | undefined> {
    fedopsLogger.interactionStarted('fetching-initial-widget-data');
    const { data } = await httpClient.getInstance().post(WIDGET_INIT_URL, {
      brand: widgetModel.getBrand(),
      context: contextProvider.getMinimalContext(),
    });
    fedopsLogger.interactionEnded('fetching-initial-widget-data');
    if (data.error) {
      throw new WidgetDataRequestFailure(data.error.message, 'getWidgetData');
    }
    dispatch('app/setInitialData', data);

    this.initialWidgetData = data;
    return this.initialWidgetData;
  }
}
