const hostUrl = new URL(window.location.href);

export const BASE_URL = hostUrl.origin;
export const WIDGET_HOST = hostUrl.host;

// urls
export const WIDGET_URL = `${BASE_URL}/contact/widget`;
export const CHATBOT_PAGE_URL = '/chatbot';

// apis
export const WIDGET_MODEL_URL = '/contact/api/widget/model';
export const WIDGET_INIT_URL = '/contact/api/widget/init';
export const WIDGET_SESSION_URL = '/contact/api/widget/session';
export const WIDGET_RUNTIME_DATA_URL = '/contact/api/widget/runtime';
export const NOTIFICATIONS_COUNT = 'notifications-count';
export const MESSAGE_MARK_AS_READ = '/contact/api/message/mark-as-read';
export const CHATBOT_OFFER_URL = '/contact/api/chatbot/offer';
export const CHATBOT_SESSION_URL = '/contact/api/chatbot/session';

// elements id
export const iframeElementId = 'c5ae986c-8e12-47d6-891a-21a875c731e1'; // a11yId

// styles constants
export const HELP_WIDGET = 'help-widget';
export const CHATBOT_WIDGET_IFRAME = 'chatbot-widget-iframe';
export const CHATBOT_WIDGET_IFRAME_CONTAINER = 'chatbot-widget-iframe-container';
export const CHATBOT_WIDGET_IFRAME_CONTAINER_ARROW = 'chatbot-widget-iframe-container-arrow';
export const CHATBOT_WIDGET_ANCHOR_CONTAINER = 'chatbot-widget-anchor-container';
export const CHATBOT_WIDGET_ANCHOR_WRAPPER = 'chatbot-widget-anchor-wrapper';
export const CHATBOT_WIDGET_BUTTON_CONTAINER = 'chatbot-widget-button-container';
export const CHATBOT_WIDGET_TOOLTIP_CONTAINER = 'chatbot-widget-tooltip-container';
export const CHATBOT_WIDGET_TOOLTIP_TITLE = 'chatbot-widget-tooltip-title';
export const CHATBOT_WIDGET_TOOLTIP_CLOSE_ICON = 'chatbot-widget-tooltip-close-icon';
export const WIDGET_DRAG_ICON_TOOLTIP = 'widget-drag-icon-tooltip';
export const WIDGET_DRAG_ICON = 'widget-drag-icon';
export const CHATBOT_WIDGET_FIXER = 'chatbot-widget-fixer';
export const WIDGET_PLACEHOLDER_EMBED_CLASS = 'widget-placeholder-embed';
export const WIDGET_DEFAULT_EMBED_CLASS = 'widget-default-embed';
export const WIDGET_HELP_CENTER_THEME_CLASS = 'widget-help-center-theme';

export const FIVE_MINUTES_IN_SECONDS = 60 * 5;
export const MOBILE_MEDIA_QUERY = 'only screen and (max-width: 760px)';
