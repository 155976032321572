import {
  WidgetData,
  WidgetLayout,
  TooltipData,
  SupportChatOrigins,
  SpecificOriginItemPosition,
  ActiveConversation,
} from '@wix/wix-help-widget-common/types';
import { connect, getState, ItemPosition, State } from '../../store';
import { assertUnreachable } from '../../utils';
import { debounce } from '../../utils/debounce';
import { diff } from 'deep-object-diff';

type CapsuleDataKey = keyof WidgetData;

const filterFalsy = <T extends object>(obj: T): T =>
  Object.entries(obj).reduce((res, [key, value]) => (!value ? res : { ...res, [key]: value }), {} as T);

export const subscribeToWidgetStateChange = (listener: (data: Partial<WidgetData>) => void): void => {
  let prevSessionDataState = getSessionData(getState());

  connect(
    '__dataCapsule',
    debounce((state) => {
      const sessionDataState = getSessionData(state);
      const changedKeys = Object.keys(diff(sessionDataState, prevSessionDataState));

      // no need to update if nothing has changed
      if (changedKeys.length === 0) {
        return;
      }

      const stateDiff: Partial<WidgetData> = (changedKeys as CapsuleDataKey[]).reduce(
        (acc: any, key: CapsuleDataKey) => {
          acc[key] = sessionDataState[key];
          return acc;
        },
        {},
      );
      listener(stateDiff);
      prevSessionDataState = sessionDataState;
    }),
  );
};

export function getSessionData(state: State): WidgetData {
  return {
    chatbot: getChatbotSessionData(state),
    widget: getWidgetSessionData(state),
    tooltip: getTooltipSessionData(state),
  };
}

function getTooltipSessionData({ tooltip: { perOffer, perType } }: State): TooltipData {
  return {
    perOffer,
    perType,
  };
}

function getWidgetSessionData({
  initParams: { placeholder, isInvokedFromHeader },
  widget: { expanded, position },
}: State): WidgetLayout | undefined {
  // we do not render a widget button when there is placeholder for chatbot, so do not save any widget data.
  // "from-the-header" widget should always reset state after page refresh, so do not need to save it
  if (placeholder?.element || isInvokedFromHeader) {
    return undefined;
  }

  return {
    expanded,
    position: roundPosition(position),
  };
}

function getChatbotSessionData({ widgetData, chatbot, initParams }: State): WidgetData['chatbot'] {
  const { origin, placeholder } = initParams;
  const { origPageName, origPlatform, conversation, position } = chatbot;
  const chatbotSessionData: WidgetData['chatbot'] = {
    origPageName,
    origPlatform,
    conversation: {
      nodeId: conversation.nodeId,
      lang: conversation.lang,
      conversationId: conversation.conversationId,
    },
  };
  // filter empty string
  const conversationData: ActiveConversation = filterFalsy<ActiveConversation>(
    chatbotSessionData.conversation!,
  );
  // TODO: move unread count from conversation state
  chatbotSessionData.conversation = Object.keys(conversationData).length > 0 ? conversationData : undefined;
  // do not add a chatbot position if there is placeholder for chatbot OR position is not defined
  if (placeholder?.element || !position) {
    return chatbotSessionData;
  }

  const isSpecificPosition = isChatbotSpecificOriginPosition(origin);

  if (isSpecificPosition) {
    const specificOriginPosition = {
      origin,
      ...roundPosition(position)!,
    };
    return {
      ...chatbotSessionData,
      position: widgetData?.chatbot?.position,
      specificOriginPositions: [
        ...(widgetData?.chatbot?.specificOriginPositions ?? []).filter(
          // remove old position for this origin
          (originPosition: SpecificOriginItemPosition) => originPosition.origin !== origin,
        ),
        specificOriginPosition,
      ],
    };
  } else {
    return {
      ...chatbotSessionData,
      position: roundPosition(position),
    };
  }
}

function isChatbotSpecificOriginPosition(origin: SupportChatOrigins): boolean {
  switch (origin) {
    case 'Editor':
      return true;
    case 'AcceptPayments':
    case 'AccountManager':
    case 'ADI':
    case 'BusinessManager':
    case 'Contact':
    case 'IntroFunnel':
    case 'Hopp':
    case 'TemplatesGallery':
    case 'TemplatesViewer':
    case 'PackagePicker':
    case 'SitesList':
    case 'Landing':
    case 'USP':
    case 'WixPayments':
    case 'test':
      return false;
    default:
      assertUnreachable(origin);
  }
}

function roundPosition(position: ItemPosition | undefined): ItemPosition | undefined {
  if (!position) {
    return undefined;
  }

  const { top, bottom, left, right } = position;
  return {
    top: Math.round(top),
    bottom: Math.round(bottom),
    left: Math.round(left),
    right: Math.round(right),
  };
}
