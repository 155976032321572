import { BusinessHours, isInWorkingTime } from '@wix/wix-chatbot-common/businessHours';
import { DealerOfferPayload } from '../types';

export const isWorkingHours = (dealerPayload: DealerOfferPayload): boolean => {
  try {
    if (dealerPayload.workingHoursSchema) {
      const workingHours = JSON.parse(dealerPayload.workingHoursSchema) as BusinessHours;
      return isInWorkingTime(new Date(), workingHours);
    }
    return true;
  } catch (error) {
    console.error('isWorkingHours failed', error.message);
    return false;
  }
};
