import { SupportChatOrigins } from '@wix/wix-help-widget-common/types';
import { iframeElementId } from '../constants';

export const getDraggableAllowedPosition = (origin: SupportChatOrigins) => {
  const padding = 12;
  const defaultHeaderHeight = 48;
  const specificHeaderHeight: Partial<Record<SupportChatOrigins, number>> = {
    Landing: 72,
    Editor: 32,
  };
  // Use real element rect in future
  const headerHeight = specificHeaderHeight[origin] ?? defaultHeaderHeight;

  return {
    left: padding,
    right: padding,
    top: padding + headerHeight,
    bottom: padding,
  };
};

export const getWidgetPositionByAnchorSelector = (
  selector: string,
  origin: SupportChatOrigins,
): { x: number; y: number } | null => {
  const anchorElement = document.querySelector(selector);
  const arrowToLeftDistance = 18;
  const specificAnchorMargin: Partial<Record<SupportChatOrigins, number>> = {
    Editor: 0,
  };
  const defaultAnchorMargin = 12;
  const anchorMargin = specificAnchorMargin[origin] ?? defaultAnchorMargin;

  const allowedRect = getDraggableAllowedPosition(origin);

  if (anchorElement) {
    const anchorRect = anchorElement.getBoundingClientRect();
    return {
      // To position the widget correctly we should shift it to the left by a value defined by css style
      x: (anchorRect.right + anchorRect.left) / 2 - arrowToLeftDistance,
      // Making sure the position is not upper than allowed by Y
      y: Math.max(allowedRect.top, anchorRect.bottom + anchorMargin),
    };
  }

  return null;
};

export const getCenterWidgetPosition = (): { x: number; y: number } | null => {
  const iframeElement = document.getElementById(iframeElementId);

  if (!iframeElement) {
    return null;
  }

  return {
    x: window.innerWidth / 2 - iframeElement.clientWidth / 2, // left center position
    y: window.innerHeight / 2 - iframeElement.clientHeight / 2, // top center position
  };
};
