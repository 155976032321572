import { DealerOfferPayloadTooltip } from '../dealer';

export enum TooltipType {
  DealerOffer = 'DealerOffer',
}

export enum TooltipIntent {
  SUCCESS = 'SUCCESS',
  ALERT = 'ALERT',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum CTAType {
  PRIMARY = 'PRIMARY',
  OUTLINED = 'OUTLINED',
  PLAIN = 'PLAIN',
}

export type CTA = DealerOfferPayloadTooltip['tooltipCta'] & {
  shouldDismissTooltip?: boolean;
};

export type TooltipRenderData = Readonly<{
  title: string;
  content?: string;
  cta?: CTA | CTA[];
  intent?: TooltipIntent;
}>;

export type TooltipRenderDataWithTimeout = TooltipRenderData &
  Readonly<{
    appearanceAfterXSeconds: string;
    closeAfterXSeconds?: string;
  }>;
