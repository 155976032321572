import { WidgetPlaceholder } from '@wix/wix-help-widget-common/types';
import { assertExists, isString } from '../utils';

export function getWidgetPlaceholderElement(placeholder?: WidgetPlaceholder): HTMLElement {
  if (!placeholder) {
    return document.body;
  }

  const { element } = placeholder;

  if (isString(element)) {
    const placeholderElement = document.querySelector<HTMLElement>(element);

    assertExists(placeholderElement, `Could not mount help widget: container is null: "${element}"`);

    return placeholderElement;
  }

  return element;
}
