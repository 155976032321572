import React from 'react';
import { sanitizeHtml } from '@wix/wix-chatbot-common/browser';
import { CHATBOT_WIDGET_TOOLTIP_TITLE } from '../../constants';

import s from './styles.scss';

export type TooltipTitleProps = Readonly<{
  title: string;
}>;

export const TooltipTitle: React.FC<TooltipTitleProps> = ({ title }) => {
  const dangerouslySetInnerHTML = React.useMemo(() => {
    return {
      __html: sanitizeHtml(title),
    };
  }, [title]);

  return (
    <div
      className={s.tooltipTitle}
      data-hook={CHATBOT_WIDGET_TOOLTIP_TITLE}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    />
  );
};
