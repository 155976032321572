import React from 'react';
import { Icon, iconsMap, LibColor } from '@wix/answers-ui-libby';

import s from './icons.scss';

export const QuestionMarkIcon: React.FC = () => {
  return (
    <Icon
      className={s.questionMark}
      icon={iconsMap.widgetQuestionMark}
      color={LibColor.white}
      ariaHidden={true}
    />
  );
};
