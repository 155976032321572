import { useLayoutEffect } from 'react';

export const wixSupportChatSDKEmitUpdateWidget = (isWidgetExpanded: boolean, unreadMessagesCount: number) => {
  window.WixSupportChatSDK.emit('updateWidgetState', {
    isWidgetExpanded,
    unreadMessagesCount,
  });
};

export const useWindowResizeCallback = (callback: () => void) => {
  useLayoutEffect(() => {
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [callback]);
};
