import { StoreonModule } from 'storeon';
import { Events, State } from '..';
import { getWidgetPlacement, BiDataWidgetPlacement } from '@wix/wix-chatbot-common/bi';

export type BiState = {
  bi: {
    // Dynamic placement value.
    // There are cases when we have more than one button to open widget on the page.
    // For example, there are GFPP button and Help button in editor, and from both of these buttons widget can be open without widget reloading.
    // empty(undefined) until `initParams` are set
    placement?: BiDataWidgetPlacement;
  };
};

export type BiEvents = {
  'bi/setWidgetPlacement': BiDataWidgetPlacement;
  'bi/resetWidgetPlacement': void;
};

export const biModule: StoreonModule<State, Events> = ({ on }) => {
  on('app/initParams', (_, { isInvokedFromHeader, placeholder }) => ({
    bi: {
      placement: getWidgetPlacement(!!placeholder?.element, !!isInvokedFromHeader),
    },
  }));

  on('bi/setWidgetPlacement', (state, placement) => ({
    bi: {
      ...state.bi,
      placement,
    },
  }));

  on('bi/resetWidgetPlacement', (state) => ({
    bi: {
      ...state.bi,
      placement: getWidgetPlacement(
        !!state.initParams.placeholder?.element,
        !!state.initParams.isInvokedFromHeader,
      ),
    },
  }));
};
