import { InitParams } from '@wix/wix-help-widget-common/types';

import { IWidget } from './widget';
import { fedopsLogger } from './fedops';
import { widgetModel } from './widgetModel';
import { httpClient } from './utils';
import { isWidgetDisabled, onWidgetFailure } from './utils/widget';
import { ChatbotWidget } from './chatbotWidget';
import { HelpWidget } from './helpWidget';

const createWidget = (initParams: InitParams): IWidget => {
  if (initParams.isInvokedFromHeader) {
    return new HelpWidget(initParams);
  }
  return new ChatbotWidget(initParams);
};

export const runWidgetApp = async (initParams: InitParams): Promise<IWidget | undefined> => {
  try {
    fedopsLogger.interactionStarted('run-widget-app');
    const authToken = initParams.authorizationToken;
    const httpClientInstance = httpClient.init(authToken);
    await widgetModel.init({
      origin: initParams.origin,
      httpClient: httpClientInstance,
    });

    const authorizationToken = widgetModel.getModel().authorizationToken;
    if (authorizationToken) {
      httpClient.setAuthToken(authorizationToken);
    }

    if (isWidgetDisabled(initParams)) {
      window.WixSupportChatSDK.emit('appRenderComplete');
      fedopsLogger.interactionEnded('run-widget-app');
      return undefined;
    }
    const widget = createWidget(initParams);

    try {
      await widget.initialize();
      widget.registerSubscribers();
    } catch (error) {
      onWidgetFailure(initParams, error);
    }

    // NOTE: we need to render the widget even if the initial data failed to load to show error state
    widget.render();

    return widget;
  } catch (error) {
    onWidgetFailure(initParams, error);
    return undefined;
  } finally {
    fedopsLogger.interactionEnded('run-widget-app');
  }
};
