// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 style=%27transform:rotate%2890deg%29;%27 viewBox=%270 0 970 970%27 %3E%3Cpath fill=%27%23737a9e%27 d=%27M90 576h.5c49.7 0 90-40.3 90-90s-40.3-90-90-90H90c-49.7 0-90 40.3-90 90s40.3 90 90 90zm527.5 0h1.1c49.7 0 90-40.3 90-90s-40.3-90-90-90h-1.1c-49.7 0-90 40.3-90 90s40.3 90 90 90zm-263 0c49.7 0 90-40.3 90-90s-40.3-90-90-90h-1.1c-49.7 0-90 40.3-90 90s40.3 90 90 90h1.1zm527 0h.5c49.7 0 90-40.3 90-90s-40.3-90-90-90h-.5c-49.7 0-90 40.3-90 90s40.3 90 90 90z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cbiYyS{display:flex;align-items:center;justify-content:center;z-index:6000;position:fixed;bottom:48px;right:24px;will-change:left,top}.vG7Xla{display:flex;align-items:center;justify-content:center;width:46px;height:46px;border:none;border-radius:50%;position:relative;background-color:#162d3d;outline:none;cursor:pointer}.DToSss{display:flex;align-items:center;justify-content:center;padding:3px;background-color:#fff;box-shadow:0 0 6px 0 rgba(0,0,0,.21);border-radius:50px 6px 6px 50px;transition:border-radius .15s cubic-bezier(0.65, 0, 0.35, 1)}.nX1X46{content:" ";background:#fff url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 50% 50%;height:46px;cursor:grabbing;display:inline-block;position:relative;opacity:1;width:12px;right:0px;transition:width .3s cubic-bezier(0.65, 0, 0.35, 1)}.widget-default-embed .cbiYyS{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}@media print{.cbiYyS{display:none !important}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-container": `cbiYyS`,
	"buttonContainer": `cbiYyS`,
	"anchor-container": `vG7Xla`,
	"anchorContainer": `vG7Xla`,
	"anchor-wrapper": `DToSss`,
	"anchorWrapper": `DToSss`,
	"draggable-handle": `nX1X46`,
	"draggableHandle": `nX1X46`
};
export default ___CSS_LOADER_EXPORT___;
