import { StoreonModule } from 'storeon';
import { WidgetData, InitParams } from '@wix/wix-help-widget-common/types';

import type { InitWidgetResponse } from '../../types';
import type { State, Events } from '..';

export type NavigationState = {
  lastEvent: number;
};

export type AppState = {
  __dataCapsule?: number; // update Data Capsule on change
  initParams: InitParams;
  widgetIframeSrc: string;
  widgetSessionId?: string;
  widgetData?: WidgetData;
  navigation?: NavigationState;
  hasFatalError?: boolean;
  // temporary tooltip (https://app.asana.com/0/1200368299997249/1204645191423137/f)
};

export type AppEvents = {
  'app/initParams': InitParams;
  'app/setWidgetIframeSrc': string;
  'app/pageNavigate': never;
  'app/setData': Partial<State>;
  'app/setInitialData': InitWidgetResponse;
  'app/setHasFatalError': boolean;

  // events prefix used as pubsub
  '@events/app/render': never;
  '@events/app/pageNavigate': never;
  '@events/app/setHasFatalError': boolean;
  '@events/app/setClientAppLoaded': never;
  '@event/app/sessionExpired': never;
};

export const appModule: StoreonModule<State, Events> = ({ on, dispatch }) => {
  on('@init', () => ({
    initParams: {
      origin: 'test',
    },
  }));

  on('app/initParams', (_, initParams) => ({
    initParams,
  }));

  on('app/setData', (_, data) => ({ ...data }));

  on('app/setInitialData', (_, { widgetSession }) =>
    widgetSession?.sessionId ? { widgetSessionId: widgetSession.sessionId } : undefined,
  );

  on('app/pageNavigate', (oldState) => {
    dispatch('@events/app/pageNavigate');

    return {
      navigation: {
        ...oldState.navigation,
        lastEvent: Date.now(),
      },
    };
  });

  on('app/setHasFatalError', (oldState, hasFatalError) => {
    dispatch('@events/app/setHasFatalError', hasFatalError);
    return {
      ...oldState,
      hasFatalError,
    };
  });

  on('app/setWidgetIframeSrc', (__, action) => {
    return {
      widgetIframeSrc: action,
    };
  });
};
