// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xe5EL2{z-index:6000;display:none;position:absolute;background-color:var(--wcw-dark-color);font-family:Madefor,HelveticaNeueW01-55Roma,HelveticaNeueW02-55Roma,HelveticaNeueW10-55Roma,"Helvetica Neue",Helvetica,Arial,"メイリオ","meiryo","ヒラギノ角ゴ pro w3","hiragino kaku gothic pro",sans-serif;box-sizing:border-box;width:369px;left:246px;top:calc(48px + 12px);padding:30px 54px 30px 36px;border-radius:8px;color:#fff;font-size:14px;line-height:18px;box-shadow:0px 6px 6px 0px rgba(0,6,36,.06),0px 0px 18px 0px rgba(0,6,36,.12)}.KKc5VM::before{content:" ";width:0;height:0;border-left:6px solid rgba(0,0,0,0);border-right:6px solid rgba(0,0,0,0);border-bottom:6px solid var(--wcw-dark-color);position:absolute;top:-6px}.xe5EL2.lFiu8w{width:421px}.xe5EL2.mns7Pv{cursor:pointer}.xe5EL2.K6_ML1{display:flex}.xe5EL2.x9BTNj .ombes8{font-style:italic;width:100%;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}.xe5EL2.x9BTNj .ombes8 a{color:var(--wsr-color-B10)}.xe5EL2 p{margin:0}.iyhe5t{font-weight:700;margin-bottom:12px}.ombes8{margin-bottom:18px}.ombes8 img{max-width:100%;max-height:100%;display:block}.nSXT0F{position:absolute;right:12px;top:12px}@media print{.xe5EL2{display:none !important}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-container": `xe5EL2`,
	"tooltipContainer": `xe5EL2`,
	"tooltip-container-with-arrow": `KKc5VM`,
	"tooltipContainerWithArrow": `KKc5VM`,
	"tooltip-container-large": `lFiu8w`,
	"tooltipContainerLarge": `lFiu8w`,
	"tooltip-container-pointer": `mns7Pv`,
	"tooltipContainerPointer": `mns7Pv`,
	"tooltip-container-visible": `K6_ML1`,
	"tooltipContainerVisible": `K6_ML1`,
	"tooltip-container-agent-message": `x9BTNj`,
	"tooltipContainerAgentMessage": `x9BTNj`,
	"tooltip-content": `ombes8`,
	"tooltipContent": `ombes8`,
	"tooltip-title": `iyhe5t`,
	"tooltipTitle": `iyhe5t`,
	"close-button": `nSXT0F`,
	"closeButton": `nSXT0F`
};
export default ___CSS_LOADER_EXPORT___;
