import type { WidgetSize } from '@wix/wix-chatbot-common/browser';
import { getCenterWidgetPosition, getWidgetPositionByAnchorSelector } from '../../utils/position';
import { StoreonDispatch } from 'storeon';
import { Events, State } from '../index';
import { openChatbotPage } from '../../utils/openChatbotPage';
import { CHATBOT_PAGE_URL } from '../../constants';
import { wixSupportChatSDKEmitUpdateWidget } from '../../components/common';
import { publishWidgetExpanded, publishWidgetSizeChange } from '../../events/publish/widgetChannel';
import { markAsRead } from '../../api/messageApi';
import { InitialWidgetPosition } from '../modules/widget';

type WidgetExpandedMiddleware = {
  dispatch: StoreonDispatch<Events>;
  state: State;
  expanded: boolean;
};

/**
 * @param dispatch
 * @param state: the current state before changing it in the reducer
 * @param expanded: the payload of expand action - indicate the new state
 * Invoked when dispatch(widget/expanded).
 */
export const widgetExpandedMiddleware = ({ dispatch, state, expanded }: WidgetExpandedMiddleware) => {
  const { isInvokedFromHeader, anchorSelector, origin } = state.initParams;

  // update position only when we open the widget
  if (expanded && !state.widget.expanded) {
    const getWidgetPosition = () => {
      if (state.widget.initialPosition === InitialWidgetPosition.Center) {
        return getCenterWidgetPosition();
      }
      if (isInvokedFromHeader && anchorSelector) {
        return getWidgetPositionByAnchorSelector(anchorSelector, origin);
      }
      return null;
    };

    const widgetPosition = getWidgetPosition();

    if (widgetPosition) {
      dispatch('chatbot/updatePosition', {
        left: widgetPosition.x,
        top: widgetPosition.y,
        bottom: 0,
        right: 0,
      });
    }
  } else if (!expanded && state.widget.expanded) {
    // Reset widget placement when widget is closed.
    // Specific(dynamic) widget placement value can be set when widget is opening and should be reset when it's closed,
    // because next time it can be opened from different place(for example, first time it can be open from GFPP, then closed and open from Help button in header)
    dispatch('bi/resetWidgetPlacement');
  }

  // if the widget has unread messages open the chatbot conversation page
  const unreadMessagesCount = state.chatbot.conversation.unreadCount;
  if (expanded && unreadMessagesCount > 0) {
    const { conversationId, nodeId, lastMessageId } = state.chatbot.conversation;
    openChatbotPage({ nodeId }); // should add referral?
    markAsRead(conversationId, lastMessageId);
  }
  const unreadCount = state.chatbot.conversation?.unreadCount || 0;
  wixSupportChatSDKEmitUpdateWidget(expanded, unreadCount);
  dispatch('@events/widget/expanded', expanded);
  publishWidgetExpanded(expanded);
};

export const widgetCurrentUrlMiddleware = ({ currentUrl, state }: { currentUrl: string; state: State }) => {
  if (currentUrl === CHATBOT_PAGE_URL) {
    const { unreadCount, conversationId, lastMessageId } = state.chatbot.conversation;
    const expanded = state.widget.expanded;
    if (unreadCount > 0 && conversationId && lastMessageId && expanded) {
      markAsRead(conversationId, lastMessageId);
    }
  }
};

export const setWidgetSizeMiddleware = ({ size, state }: { size: WidgetSize; state: State }) => {
  if (state.widget.size !== size) {
    publishWidgetSizeChange(size);
  }
};
