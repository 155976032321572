import { getState } from '../store';
import {
  hwPublishContextChange,
  hwPublishLoadPageUrl,
  hwPublishNewDealerOfferParams,
  hwPublishOrigParamsChange,
} from '../events/publish/widgetChannel';
import { getStartUrlFromDealerOffer } from './startUrl';
import { getContext } from '../utils/context';

export const publishContext = async (): Promise<void> => {
  const { context: contextState } = getState();
  if (!contextState?.user?.userId) {
    return;
  }
  const context = await getContext();

  hwPublishContextChange(context);
};

export const publishStartUrl = async (): Promise<void> => {
  const {
    chatbot: { dealerOffer, isChatbotSessionActive },
    widgetIframeSrc,
  } = getState();
  hwPublishLoadPageUrl(
    window.location.href,
    dealerOffer && !isChatbotSessionActive ? widgetIframeSrc : undefined,
  );
};

export const publishNewDealerOfferParams = async (): Promise<void> => {
  const {
    chatbot: { dealerOffer, isChatbotSessionActive },
  } = getState();
  const urlFromDealerOffer = dealerOffer && getStartUrlFromDealerOffer({ dealerOffer });

  if (urlFromDealerOffer && !isChatbotSessionActive) {
    const { searchParams } = new URL(urlFromDealerOffer);
    const dealerOfferParams = new Map(searchParams.entries());

    hwPublishNewDealerOfferParams(dealerOfferParams);
  }
};

export const publishOrigParams = async (): Promise<void> => {
  const state = getState();
  const {
    chatbot: { origPageName, origPlatform },
  } = state;
  hwPublishOrigParamsChange({ origPlatform, origPageName });
};
