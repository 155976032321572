import React from 'react';
import { CTA, TooltipRenderData } from '../../types';
import { TooltipTitle } from './TooltipTitle';
import { TooltipContent } from './TooltipContent';
import { TooltipCTA } from './TooltipCTA';

type WidgetTooltipContentProps = TooltipRenderData & {
  onDismissClick: (e: React.MouseEvent, cta: CTA) => void;
  onSubmitClick: (e: React.MouseEvent, cta: CTA) => void;
};

export const WidgetTooltipContent: React.FC<WidgetTooltipContentProps> = ({
  cta,
  title,
  content,
  onDismissClick,
  onSubmitClick,
}) => {
  const hasCTA = Array.isArray(cta) ? !!cta.length : !!cta?.key;

  return (
    <>
      <TooltipTitle title={title} />
      {content && <TooltipContent content={content} />}
      {hasCTA && <TooltipCTA cta={cta} onDismissClick={onDismissClick} onSubmitClick={onSubmitClick} />}
    </>
  );
};
