import React from 'react';
import ReactDOM from 'react-dom';
import { dispatch, getState } from '../store';
import { getWidgetPlaceholderElement } from './getWidgetPlaceholderElement';
import { Root } from './Root';

export const renderWidget = (): void => {
  const { initParams, widget } = getState();
  if (widget.rendered) {
    dispatch('@events/app/render');
    return;
  }
  const containerElement = getWidgetPlaceholderElement(initParams.placeholder);
  const rootEl = document.createElement('div');
  containerElement.appendChild(rootEl);

  ReactDOM.render(<Root />, rootEl);

  dispatch('@events/app/render');
  dispatch('widget/rendered', true);
};
