import React, { CSSProperties, FC } from 'react';

const svgElementStyle: CSSProperties = {
  transform: 'rotate(90deg)',
  pointerEvents: 'none',
};

export const DragIconSvg: FC = () => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={svgElementStyle}
  >
    <g id="Locked=False">
      <g id="Box">
        <path
          id="Glyph"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1H8V3H6V1ZM6 6H8V8H6V6ZM10 1H12V3H10V1ZM10 6H12V8H10V6ZM6 11H8V13H6V11ZM10 11H12V13H10V11ZM6 16H8V18H6V16ZM10 16H12V18H10V16ZM6 21H8V23H6V21ZM10 21H12V23H10V21Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
