import { Duplexer } from '@wix/duplexer-js';
import { useTabActive } from '@wix/wix-chatbot-common/hooks';
import { useEffect, useMemo, useRef } from 'react';

import { ChatbotMessageSubscriber } from '../duplexer/ChatbotMessageSubscriber';
import { widgetModel } from '../widgetModel';
import { getState } from '../store';
import { enableNotifications } from '../utils/enableFeature';

export const useChatbotMessagesSubscriber = () => {
  const config = widgetModel.getConfig();
  const {
    initParams: { authorizationToken },
  } = getState();

  const duplexerConfig = config?.duplexer;
  const messageChannels = duplexerConfig?.messageChannels;

  const messageSubscriberRef = useRef<ChatbotMessageSubscriber | undefined>(undefined);
  const isSubscriberNeeded = enableNotifications(widgetModel.getExperiments());
  // Extract duplexer factory to a different place when more subscribers will be needed
  const duplexerInstance = useMemo(() => {
    if (!isSubscriberNeeded) {
      return null;
    }
    const duplexer = config?.duplexer;
    const duplexerURL = duplexer?.duplexerURL || '';

    return new Duplexer(duplexerURL, {
      instanceUpdater: {
        getInstance: () => authorizationToken || '',
      },
    });
  }, [authorizationToken, config?.duplexer]);

  const isTabActive = useTabActive();

  useEffect(() => {
    if (!duplexerInstance) {
      return;
    }

    const chatbotDefId = messageChannels?.chatbotMessage || '';

    messageSubscriberRef.current = new ChatbotMessageSubscriber(duplexerInstance, chatbotDefId, isTabActive);

    return () => {
      messageSubscriberRef.current?.unsubscribe();
    };
    // skipping isTabActive ref as there is an separate effect for it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplexerInstance, messageChannels, isSubscriberNeeded]);

  useEffect(() => {
    messageSubscriberRef.current?.setTabActive(isTabActive);
  }, [isTabActive]);
};
