import type { Offer } from '@wix/ambassador-dealer-v1-serving-offer/types';
import type { DealerOfferDetails, DealerOfferDynamicParams } from '../types';

export const getOfferData = (offer?: Offer): DealerOfferDetails | undefined => {
  const creative = offer?.asset?.creative;
  const payloadString = offer?.asset?.payloadJson || '';
  if (!payloadString && !creative) {
    return undefined;
  }

  const dynamicParams: DealerOfferDynamicParams = {};
  try {
    const interactionId = offer?.asset?.dynamicParams?.interactionId || null;
    dynamicParams.interactionId = JSON.parse(String(interactionId));
  } catch (e) {
    /** could not parse, do nothing */
  }

  try {
    const payload = creative ? creative : JSON.parse(payloadString);
    return {
      guid: offer?.offerGuid,
      payload: {
        ...payload,
        ...dynamicParams,
      },
    };
  } catch (e) {
    throw new Error(`Failed to parse payload string: ${e.message}`);
  }
};
