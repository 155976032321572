import type { ActiveChatSession } from '@wix/wix-help-widget-common/types';

import { httpClient } from '../utils';
import { fedopsLogger } from '../fedops';
import { ChatbotSessionRequestFailure } from '../errors';
import { CHATBOT_SESSION_URL } from '../constants';

export class ChatbotSessionService {
  async fetchActiveSession(): Promise<ActiveChatSession | undefined> {
    fedopsLogger.interactionStarted('fetching-chatbot-active-session-data');
    const { data } = await httpClient.getInstance().get(CHATBOT_SESSION_URL);
    fedopsLogger.interactionEnded('fetching-chatbot-active-session-data');
    if (data.error) {
      throw new ChatbotSessionRequestFailure(data.error.message, 'getChatbotSession');
    }

    return data;
  }
}

export const chatbotSessionService = new ChatbotSessionService();
