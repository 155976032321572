// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.osZGSI{display:flex;align-items:center;justify-content:center;font-family:Madefor,HelveticaNeueW01-55Roma,HelveticaNeueW02-55Roma,HelveticaNeueW10-55Roma,"Helvetica Neue",Helvetica,Arial,"メイリオ","meiryo","ヒラギノ角ゴ pro w3","hiragino kaku gothic pro",sans-serif;position:absolute;top:-2px;left:-2px;border:solid 2px #fff;background-color:#60bc57;color:#fff;font-size:10px;height:1.3em;min-width:1.3em;padding:2px;box-shadow:0 0 6px 0 rgba(0,0,0,.21);border-radius:30px;display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": `osZGSI`
};
export default ___CSS_LOADER_EXPORT___;
