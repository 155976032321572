import { Box, Button, EmptyState } from '@wix/design-system';
import React, { FC, useMemo, MouseEventHandler } from 'react';
import { Trans, useTranslation } from '@wix/wix-i18n-config';
import { ErrorIcon } from './ErrorIcon';

type Props = {
  onRefresh: MouseEventHandler<HTMLElement>;
};

export const ErrorScreen: FC<Props> = ({ onRefresh }) => {
  const { t } = useTranslation();
  const subtitle = useMemo(
    () => (
      <Trans
        t={t}
        values={{ link: t('fatal_error_state_status_page') }}
        components={{
          a: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a target="_blank" rel="noreferrer" href="https://status.wix.com" />
          ),
        }}
      >
        fatal_error_state_subtitle
      </Trans>
    ),
    [t],
  );

  return (
    <Box width="100%" align="center" height="100%" verticalAlign="middle">
      <EmptyState
        theme="page-no-border"
        image={<ErrorIcon />}
        title={t('fatal_error_state_title')}
        subtitle={subtitle}
      >
        <Button priority="secondary" onClick={onRefresh}>
          {t('fatal_error_state_refresh')}
        </Button>
      </EmptyState>
    </Box>
  );
};
