import React, {
  MutableRefObject,
  RefObject,
  ReactNode,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ItemPosition } from '../../store';
import { CHATBOT_WIDGET_FIXER } from '../../constants';
import s from './styles.scss';
import { useWindowResizeCallback } from '../common';

interface DraggableProps {
  children: ReactNode;
  position: ItemPosition | null;
  draggableHandleRef: RefObject<HTMLElement>;
  allowedPosition?: ItemPosition;
  isDragEnabled?: boolean;
  onDragStart?: () => void;
  onDragEnd?: (rect: DOMRect) => void;
  onDraggablePositionSet?: (left: number, top: number) => void;
}

const setDraggablePosition = ({
  element,
  left,
  top,
}: {
  element: HTMLElement;
  left: string;
  top: string;
}) => {
  element.style.left = left;
  element.style.top = top;
  element.style.bottom = 'auto';
  element.style.right = 'auto';
};

export const Draggable = React.forwardRef<
  HTMLDivElement,
  DraggableProps & React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const {
    children,
    position,
    allowedPosition,
    draggableHandleRef,
    isDragEnabled,
    onDragStart,
    onDragEnd,
    onDraggablePositionSet,
    ...rest
  } = props;

  const localRef = useRef<HTMLDivElement>();
  const containerRef = (ref || localRef) as MutableRefObject<HTMLDivElement>;
  const fixerRef = createRef<HTMLDivElement>();

  const dragStartX = useRef<number>(0);
  const dragStartY = useRef<number>(0);
  const minX = useRef<number>(0);
  const minY = useRef<number>(0);
  const maxX = useRef<number>(0);
  const maxY = useRef<number>(0);
  const step = useRef<number>();
  const [lastSetPosition, setLastSetPosition] = useState<ItemPosition | undefined>();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  // Attempt to move to an untrusted position, so the final position might be adjusted depending on allowed rect
  const moveToPosition = useCallback(
    (x: number, y: number) => {
      let left = x;
      let top = y;

      left = Math.min(Math.max(left, minX.current), maxX.current);
      top = Math.min(Math.max(top, minY.current), maxY.current);

      if (containerRef.current) {
        setDraggablePosition({
          element: containerRef.current,
          left: `${left}px`,
          top: `${top}px`,
        });
        onDraggablePositionSet?.(left, top);
      }
    },
    [containerRef],
  );

  const moveToPositionAnimated = useCallback(
    (x: number, y: number) => {
      if (step.current) {
        cancelAnimationFrame(step.current);
      }

      step.current = requestAnimationFrame(() => {
        moveToPosition(x, y);
      });
    },
    [moveToPosition],
  );

  const saveAllowedPositions = useCallback(() => {
    const rect = containerRef.current.getBoundingClientRect();

    // Saving min/max available X/Y on mousedown
    maxX.current = window.innerWidth - rect.width - (allowedPosition?.right || 0);
    maxY.current = window.innerHeight - rect.height - (allowedPosition?.bottom || 0);
    minX.current = allowedPosition?.left || 0;
    minY.current = allowedPosition?.top || 0;
  }, [allowedPosition, containerRef]);

  const moveDraggableToLastSetPosition = useCallback(() => {
    if (lastSetPosition) {
      saveAllowedPositions();

      const { top, left, bottom, right } = lastSetPosition;

      if (bottom && right) {
        const element = containerRef.current;
        // This dom operation might affect performance
        const rect = element.getBoundingClientRect();
        const x = right - rect.width;
        const y = bottom - rect.height;
        moveToPosition(x, y);
      } else if (top && left) {
        const x = left;
        const y = top;
        moveToPosition(x, y);
      }
    }
  }, [containerRef, lastSetPosition, moveToPosition, saveAllowedPositions]);

  const moveDraggableToLastSetPositionAnimated = useCallback(() => {
    if (step.current) {
      cancelAnimationFrame(step.current);
    }
    step.current = requestAnimationFrame(moveDraggableToLastSetPosition);
  }, [moveDraggableToLastSetPosition]);

  const mouseUpHandler = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setLastSetPosition(rect);
      setIsMouseDown(false);
      setIsDragging(false);
      onDragEnd?.(rect);
    }
  }, [containerRef, onDragEnd]);

  const mouseDownHandler = useCallback(
    ({ nativeEvent: event }) => {
      if (
        // If draggable handle is not defined using container element as a handle
        // NOTE: This is a sensitive place, as the handle element should be on top when clicked.
        event.target === (draggableHandleRef?.current || containerRef.current)
      ) {
        event.stopPropagation();
        event.preventDefault();

        const rect = containerRef.current.getBoundingClientRect();

        dragStartX.current = event.clientX - rect.left;
        dragStartY.current = event.clientY - rect.top;

        saveAllowedPositions();
        setIsMouseDown(true);
      }
    },
    [draggableHandleRef, containerRef, saveAllowedPositions],
  );

  const mouseMoveHandler = useCallback(
    (event) => {
      if (event.buttons !== 1) {
        // Stop dragging if mouse button was released out of the window
        mouseUpHandler();
        return;
      }

      if (!isDragging) {
        setIsDragging(true);
        onDragStart?.();
      }

      moveToPositionAnimated(event.clientX - dragStartX.current, event.clientY - dragStartY.current);
    },
    [mouseUpHandler, moveToPositionAnimated, onDragStart, isDragging],
  );

  // Effect to update the position if position prop has changed
  useEffect(() => {
    if (position) {
      setLastSetPosition(position);
    }
  }, [position]);

  // Effect to position the widget by the last set position
  useEffect(() => {
    moveDraggableToLastSetPosition();
  }, [lastSetPosition, moveDraggableToLastSetPosition]);

  useEffect(() => {
    const onTransitionEnd = () => {
      saveAllowedPositions();
      const rect = containerRef.current.getBoundingClientRect();

      // need to update arrow position according to new size after animation
      onDraggablePositionSet?.(rect.x, rect.y);

      // check if widget after animation is not out of screen. If yes, fit it
      if (rect.x > maxX.current) {
        moveToPositionAnimated(maxX.current, rect.y);
      }
      if (rect.y > maxY.current) {
        moveToPositionAnimated(rect.x, maxY.current);
      }
    };

    // recalculate position when size animation is completed(to keep widget in allowed position after maximize)
    containerRef.current?.addEventListener('transitionend', onTransitionEnd);
    return () => containerRef.current?.removeEventListener('transitionend', onTransitionEnd);
  }, [moveToPositionAnimated, saveAllowedPositions, onDraggablePositionSet]);

  useWindowResizeCallback(moveDraggableToLastSetPositionAnimated);

  return (
    <>
      <div ref={containerRef} onMouseDown={mouseDownHandler} {...rest}>
        {children}
      </div>
      <div
        className={isMouseDown ? s.fixer : s.hidden}
        ref={fixerRef}
        data-hook={isMouseDown ? CHATBOT_WIDGET_FIXER : null}
        onMouseMove={mouseMoveHandler}
        onMouseUp={mouseUpHandler}
      ></div>
    </>
  );
});

Draggable.defaultProps = {
  isDragEnabled: true,
};
