import type { DealerOfferDetails } from '../types';
import type { ItemPosition } from '../../store';

export const getPositionFromOffer = (offer: DealerOfferDetails | undefined): ItemPosition | undefined => {
  if (offer && offer.payload.widgetOffsetRight && offer.payload.widgetOffsetBottom) {
    return {
      // top and left doesn't affect anchor/iframe position
      top: 0,
      left: 0,
      right: window.innerWidth - Number(offer.payload.widgetOffsetRight),
      bottom: window.innerHeight - Number(offer.payload.widgetOffsetBottom),
    };
  }

  return undefined;
};
