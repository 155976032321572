import {
  Context,
  OrigParams,
  WIDGET_SDK_CHANNEL,
  WIDGET_SUPPORT_CHATBOT,
  WidgetEvents,
  WidgetMessageData,
  WixSupportChatSDKInterface,
  WidgetInternalSDKMethods,
  SdkMethodResponseResult,
  InternalSdkMethodResponseResult,
  WidgetSize,
} from '@wix/wix-chatbot-common/browser';
import { iframeElementId } from '../../constants';

export enum LOG_EVENTS {
  TOOLTIP_OPEN_MISSING_PAYLOAD = 'tooltipOpenMissingPayload',
  TOOLTIP_CLICK_MISSING_PAYLOAD = 'tooltipClickMissingPayload',
  GET_START_URL_FROM_OFFER_PARSE_ERROR = 'getStartUrlFromOfferParseError',
  BUILD_WIDGET_URL_ERROR = 'buildWidgetUrlError',
  CONTEXT_PAYLOAD = 'contextPayload',
  CALL_SDK_METHOD_RESPONSE = 'callSdkMethodResponse',
  CALL_INTERNAL_HANDLER_RESPONSE = 'callInternalHandlerResponse',
}

const WIDGET_EVENT_NAVIGATE = 'navigate';
const WIDGET_EVENT_TOGGLE_EXPANDED = 'toggleExpanded';
const WIDGET_SDK_TRIGGERED_EVENT = 'widgetSdkTriggeredEvent';
const WIDGET_EVENT_HELP_CENTER = 'helpCenter';

type WidgetEventType =
  | LOG_EVENTS
  | typeof WIDGET_EVENT_NAVIGATE
  | typeof WIDGET_EVENT_TOGGLE_EXPANDED
  | typeof WIDGET_SDK_TRIGGERED_EVENT
  | typeof WIDGET_EVENT_HELP_CENTER;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WidgetEventPayload = Record<string, any>;

const publishMessage = (
  event: WidgetEvents,
  widgetEventType: WidgetEventType,
  payload: WidgetEventPayload = {},
  type: string = WIDGET_SUPPORT_CHATBOT,
): void => {
  const message: WidgetMessageData = {
    type,
    event,
    payload,
    widgetEventType,
  };

  const iframe = document.getElementById(iframeElementId) as HTMLIFrameElement | null;

  if (iframe?.src && iframe.contentWindow) {
    iframe.contentWindow.postMessage(message);
  }
};

export const hwPublishLoadPageUrl = (loadPageUrl: string, startUrl?: string) => {
  publishMessage(WidgetEvents.UPDATE_LOAD_PAGE_URL, WIDGET_EVENT_NAVIGATE, {
    loadPageUrl,
    startUrl,
  });
};

export const hwPublishNewDealerOfferParams = (dealerOfferParams: Map<string, string>) => {
  publishMessage(WidgetEvents.UPDATE_DEALER_OFFER_PARAMS, WIDGET_EVENT_NAVIGATE, { dealerOfferParams });
};

export const hwPublishOrigParamsChange = (payload: OrigParams) => {
  publishMessage(WidgetEvents.UPDATE_ORIG_PARAMS, WIDGET_EVENT_NAVIGATE, payload);
};

export const hwPublishLog = (widgetEventType: LOG_EVENTS, payload: WidgetEventPayload = {}) => {
  publishMessage(WidgetEvents.LOG_MESSAGE, widgetEventType, payload);
};

export const hwPublishContextChange = (payload: Context) => {
  publishMessage(WidgetEvents.CONTEXT_CHANGE, LOG_EVENTS.CONTEXT_PAYLOAD, payload, WIDGET_SDK_CHANNEL);
};

export const hwPublishCallSdkMethodResponse = (payload: {
  requestId: string;
  result: SdkMethodResponseResult;
  methodName: keyof WixSupportChatSDKInterface;
  methodParams: any;
}) => {
  publishMessage(
    WidgetEvents.CALL_SDK_METHOD_RESPONSE,
    LOG_EVENTS.CALL_SDK_METHOD_RESPONSE,
    payload,
    WIDGET_SDK_CHANNEL,
  );
};

export const hwPublishCallInternalHandlerResponse = (payload: {
  requestId: string;
  result: InternalSdkMethodResponseResult;
  methodName: keyof WidgetInternalSDKMethods;
  methodParams: any;
}) => {
  publishMessage(
    WidgetEvents.CALL_INTERNAL_HANDLER_RESPONSE,
    LOG_EVENTS.CALL_INTERNAL_HANDLER_RESPONSE,
    payload,
    WIDGET_SDK_CHANNEL,
  );
};

export const publishOpenChatbotPage = () => {
  publishMessage(WidgetEvents.OPEN_CHATBOT_PAGE, WIDGET_EVENT_HELP_CENTER);
};

export const publishOpenSearchPage = (query: string) => {
  publishMessage(WidgetEvents.OPEN_SEARCH_PAGE, WIDGET_EVENT_HELP_CENTER, {
    query,
  });
};

export const publishNewArticleUri = (articleUri: string) => {
  publishMessage(WidgetEvents.UPDATE_ARTICLE_URI, WIDGET_EVENT_TOGGLE_EXPANDED, {
    articleUri,
  });
};

export const publishWidgetExpanded = (isExpanded: boolean) => {
  publishMessage(WidgetEvents.TOGGlE_WIDGET_EXPANDED, WIDGET_EVENT_TOGGLE_EXPANDED, {
    expanded: isExpanded,
  });
};

export const publishOpenHomePage = () => {
  publishMessage(WidgetEvents.OPEN_HOME_PAGE, WIDGET_EVENT_HELP_CENTER);
};

export const publishSessionRefresh = () => {
  publishMessage(WidgetEvents.SESSION_REFRESH, WIDGET_EVENT_HELP_CENTER);
};

export const publishWidgetSizeChange = (size: WidgetSize) => {
  publishMessage(WidgetEvents.WIDGET_SIZE_CHANGE, WIDGET_EVENT_HELP_CENTER, { size });
};

export const publishWidgetMobileViewChanged = (isMobile: boolean) => {
  publishMessage(WidgetEvents.WIDGET_MOBILE_VIEW_CHANGED, WIDGET_EVENT_HELP_CENTER, { isMobile });
};
