import { factory } from '@wix/web-bi-logger';
import { contextToBiEvent, getChatbotView } from '@wix/wix-chatbot-common/browser';
import { getState, ItemPosition } from '../store';
import { InitParams } from '@wix/wix-help-widget-common/types';
import { WidgetClosingTrigger, getWidgetPlacement } from '@wix/wix-chatbot-common/bi';
import { WIDGET_LOAD_FAIL_PHASE, TooltipRenderData } from '../types';
import { hwPublishLog, LOG_EVENTS } from '../events/publish/widgetChannel';
import { getContext } from '../utils/context';

const getQueryParam = (url: string, paramName: string): string | null => {
  if (!url) {
    return null;
  }

  try {
    return new URL(url).searchParams.get(paramName);
  } catch (e) {
    const errorMessage = `getQueryParam: could not parse URL: "${url}": ${e.message}`;
    console.error(errorMessage);
    hwPublishLog(LOG_EVENTS.BUILD_WIDGET_URL_ERROR, {
      errorMessage,
    });
    throw e;
  }
};

const biChatbot = factory({ endpoint: 'chatbot' })
  .updateDefaults({
    src: 4,
    current_page_name: document.title,
    msid: '',
    node_name: '',
  })
  .logger();

const sendBiEvent = async (data: any) => {
  const {
    widgetIframeSrc,
    chatbot: { origPageName, origPlatform },
    initParams: { isInvokedFromHeader, placeholder },
  } = getState();

  const context = await getContext();

  const biEventContext = contextToBiEvent(context);
  const msid = typeof data.msid === 'string' ? data.msid : context.metaSiteId;
  const chatbot_view = getChatbotView(context);

  biChatbot.log({
    referral: getQueryParam(widgetIframeSrc, 'referral'),
    ...data,
    ...biEventContext,
    orig_page_name: origPageName,
    orig_platform: origPlatform,
    ...(msid ? { msid } : null),
    chatbot_view,
    placement: getWidgetPlacement(!!placeholder?.element, !!isInvokedFromHeader),
  });
};
// The event is triggered whenever a user drags the widget
export const biDragWidget = (newPosition: ItemPosition) => {
  const {
    chatbot: { conversation },
    widget: { expanded, position },
    widgetSessionId,
  } = getState();

  sendBiEvent({
    evid: 1108,
    conversation_id: conversation.conversationId,
    lang: conversation.lang,
    new_position: `{${newPosition.top}, ${newPosition.left}}`,
    old_position: '{' + [position?.top, position?.left].filter(Boolean).join(', ') + '}',
    widget_state: expanded ? 'open' : 'closed',
    node_id: conversation.nodeId,
    session_id: widgetSessionId,
  });
};

// The chatbot widget is opened after being minimized
export const biOpenWidget = () => {
  const {
    chatbot: { conversation },
    widgetSessionId,
    context: { user },
  } = getState();

  sendBiEvent({
    evid: 1109,
    conversation_id: conversation.conversationId,
    lang: conversation.lang,
    node_id: conversation.nodeId,
    notificationCount: conversation.unreadCount,
    session_id: widgetSessionId,
    priority_class: user?.careProfile?.userPriorityClass,
  });
};
// The chatbot widget is closed
export const biCloseWidget = (closingTrigger: WidgetClosingTrigger) => {
  const {
    chatbot: { conversation },
    widgetSessionId,
  } = getState();

  sendBiEvent({
    evid: 1110,
    closing_trigger: closingTrigger,
    conversation_id: conversation.conversationId,
    lang: conversation.lang,
    node_id: conversation.nodeId,
    session_id: widgetSessionId,
  });
};

// Chatbot widget is shown to the user
export const biWidgetLoaded = () => {
  const {
    initParams,
    chatbot: { dealerOffer, conversation },
    widget: { expanded },
  } = getState();

  sendBiEvent({
    evid: 1111,
    conversation_id: conversation.conversationId,
    lang: conversation.lang,
    node_id: conversation.nodeId,
    notificationCount: 0,
    dealer_offer_id: dealerOffer?.guid || '',
    init_params: JSON.stringify(initParams),
    widget_state: expanded ? 'open' : 'closed',
  });
};

// The user fails to open the chatbot widget
export const biWidgetLoadFailure = ({
  failReason,
  failDetails = '',
  initParams,
}: {
  failReason: WIDGET_LOAD_FAIL_PHASE;
  failDetails: string;
  initParams: InitParams;
}) => {
  const {
    chatbot: { conversation },
  } = getState();
  sendBiEvent({
    evid: 1114,
    referral: document.location.href,
    conversation_id: conversation.conversationId,
    fail_reason: failReason,
    fail_details: failDetails,
    init_params: JSON.stringify(initParams),
  });
};

// The user is shown a tooltip near the chatbot widget
// isOnetime - means that this tooltip is displayed only once to the user
export const biTooltipLoad = ({ title, content }: TooltipRenderData, isOnetime = false) => {
  const {
    chatbot: { conversation },
    widget: { expanded },
    initParams: { msid },
    widgetSessionId,
  } = getState();

  sendBiEvent({
    evid: 1115,
    conversation_id: conversation.conversationId,
    current_page_name: document.title,
    lang: conversation.lang,
    tooltip_name: title,
    tooltip_text: content,
    widget_state: expanded ? 'open' : 'closed',
    msid,
    is_onetime_tooltip: isOnetime,
    session_id: widgetSessionId,
  });
};

// The chatbot widget is opened after the user clicks on a tooltip
export const biTooltipClick = ({ title, content }: TooltipRenderData, isOnetime = false) => {
  const {
    chatbot: { conversation },
    widget: { expanded },
    widgetSessionId,
  } = getState();

  sendBiEvent({
    evid: 1116,
    conversation_id: conversation.conversationId,
    current_page_name: document.title,
    lang: conversation.lang,
    tooltip_name: title,
    tooltip_text: content,
    widget_state: expanded ? 'open' : 'closed',
    is_onetime_tooltip: isOnetime,
    session_id: widgetSessionId,
  });
};

// The user closes a chatbot tooltip
export const biTooltipClose = ({ title, content }: TooltipRenderData, isOnetime = false) => {
  const {
    chatbot: { conversation },
    widget: { expanded },
    widgetSessionId,
  } = getState();

  sendBiEvent({
    evid: 1117,
    conversation_id: conversation.conversationId,
    current_page_name: document.title,
    lang: conversation.lang,
    tooltip_name: title,
    tooltip_text: content,
    widget_state: expanded ? 'open' : 'closed',
    is_onetime_tooltip: isOnetime,
    session_id: widgetSessionId,
  });
};

// The user clicked a button on a tooltip
export const tooltipButtonClick = ({
  buttonId,
  buttonName,
  tooltipName,
  tooltipText,
  button_type,
  button_type_id,
}: {
  buttonId: string;
  buttonName: string;
  tooltipName: string;
  tooltipText: string;
  // button_type and button_type_id are available only for USP tooltips
  button_type?: string;
  button_type_id?: number;
}) => {
  const {
    chatbot: { conversation },
    initParams: { msid, lang },
    widgetSessionId,
  } = getState();

  sendBiEvent({
    evid: 1125,
    button_id: buttonId,
    button_name: buttonName,
    current_page_name: document.title,
    lang: conversation.lang,
    msid,
    tooltip_name: tooltipName,
    tooltip_text: tooltipText,
    lng: lang,
    button_type,
    button_type_id,
    session_id: widgetSessionId,
  });
};

// The notification about chatbot messages is displayed
export const chatbotNotificationReceived = ({ counterValue }: { counterValue: number }) => {
  sendBiEvent({
    evid: 1162,
    counter_value: counterValue,
  });
};
