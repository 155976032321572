// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UuviBG{overflow:hidden;border:none;width:100%;height:100%}.KtyRjt{visibility:hidden}.sh0DW3{z-index:6000;display:block;position:fixed;outline:none;will-change:left,top;background:#fff}.snM5P7{content:" ";width:0;height:0;border-left:6px solid rgba(0,0,0,0);border-right:6px solid rgba(0,0,0,0);border-bottom:6px solid var(--wcw-dark-color);position:absolute;top:-6px}.fQSAW2{content:" ";width:0;height:0;border-left:6px solid rgba(0,0,0,0);border-right:6px solid rgba(0,0,0,0);border-bottom:6px solid #fff;position:absolute;top:-6px}.YTX5e9{overflow:hidden;width:100%;height:100%;line-height:0}.widget-default-embed .sh0DW3{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;bottom:3vh;right:1.5vw}.widget-default-embed .sh0DW3{display:block;width:400px;height:85vh;min-height:300px;max-height:680px;border-radius:8px;box-shadow:0 8px 8px 0 rgba(0,6,36,.12),0 3px 24px 0 rgba(0,6,36,.18);transition:width .2s ease-in-out,height .2s ease-in-out,max-height .2s ease-in-out}.widget-default-embed .uMdPLy{width:640px;height:90vh;max-height:762px}.widget-default-embed .YTX5e9{border-radius:8px}.widget-placeholder-embed .sh0DW3{position:relative;width:100%;height:100%}@media only screen and (max-width: 760px){.widget-default-embed .sh0DW3{border-radius:0;top:0;right:0;padding:0;z-index:9999;height:100vh;width:100vw;max-height:none;background-color:#f0f4f7}.widget-default-embed .UuviBG{width:100vw;max-height:none;border-radius:0;box-shadow:none}.widget-default-embed .YTX5e9{border-radius:0}}@media only screen and (max-width: 760px){body.L1rged{overflow:hidden}}@media print{.sh0DW3{display:none !important}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": `UuviBG`,
	"hidden": `KtyRjt`,
	"iframe-container": `sh0DW3`,
	"iframeContainer": `sh0DW3`,
	"iframe-container-help-widget-arrow-dark": `snM5P7`,
	"iframeContainerHelpWidgetArrowDark": `snM5P7`,
	"iframe-container-help-widget-arrow-white": `fQSAW2`,
	"iframeContainerHelpWidgetArrowWhite": `fQSAW2`,
	"overflow-container": `YTX5e9`,
	"overflowContainer": `YTX5e9`,
	"iframe-container_maximized": `uMdPLy`,
	"iframeContainerMaximized": `uMdPLy`,
	"chatbot-widget-noscroll": `L1rged`,
	"chatbotWidgetNoscroll": `L1rged`
};
export default ___CSS_LOADER_EXPORT___;
