import { dateExpired } from '@wix/wix-chatbot-common/browser';
import { TooltipState, tooltipTypesMapping } from '../../store/modules/tooltip';
import { TooltipType } from '../../types';

export type IsClosedParams = {
  tooltipCloseTimeout: number;
  tooltip?: TooltipState;
  dealerOfferGuid?: string;
  tooltipType?: TooltipType;
};

// true if tooltip state has perType closedAt or perOffer closedAt
export const isClosed = ({
  tooltipCloseTimeout,
  tooltip,
  dealerOfferGuid,
  tooltipType,
}: IsClosedParams): boolean => {
  if (!tooltip) {
    return false;
  }

  if (
    tooltip.perType?.find(
      ({ type, closedAt }) =>
        type === tooltipTypesMapping[tooltipType!] && !dateExpired(closedAt * 1000, tooltipCloseTimeout),
    )
  ) {
    return true;
  }

  if (!dealerOfferGuid) {
    return false;
  }

  return !!tooltip.perOffer?.find(
    ({ offerGuid, closedAt }) =>
      offerGuid === dealerOfferGuid && !dateExpired(closedAt * 1000, tooltipCloseTimeout),
  );
};
