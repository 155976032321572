export const DEFAULT_DEBOUNCE_DELAY = 200; // ms

export type Callback<T = void> = (data: T) => void;

export const debounce = <T = void>(fn: Callback<T>, delay = DEFAULT_DEBOUNCE_DELAY): Callback<T> => {
  let timeout: number;

  return (data): void => {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = 0;
    }

    timeout = window.setTimeout(() => {
      fn(data);
    }, delay);
  };
};
