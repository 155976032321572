import React, {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { WidgetSize } from '@wix/wix-chatbot-common/browser';
import { dispatch, getState, ItemPosition, onStoreEvent, State } from '../../store';
import {
  CHATBOT_WIDGET_IFRAME_CONTAINER,
  CHATBOT_WIDGET_IFRAME_CONTAINER_ARROW,
  HELP_WIDGET,
} from '../../constants';
import { biDragWidget } from '../../bi';
import { WidgetIframe } from './WidgetIframe';

import styles from './iframe.scss';
import { Draggable } from '../Draggable';
import { isMobile } from '../../utils';
import { useWindowResizeCallback } from '../common';
import { getDraggableAllowedPosition } from '../../utils/position';
import { WidgetClosingTrigger } from '@wix/wix-chatbot-common/bi';
import { Loader } from '../Loader';
import { ErrorScreen } from '../ErrorScreen';
import { DragIcon } from './DragIcon';
import { collapseWidget } from '../../sdk/methods';
import { publishWidgetMobileViewChanged } from '../../events/publish/widgetChannel';
import { InitialWidgetPosition } from '../../store/modules/widget';

const ARROW_SIDE_DISTANCE = 6; // px
const ARROW_WIDTH = 12; // px

export const WidgetContainer: React.FC = React.memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const draggableRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const iframeRef = useRef<HTMLIFrameElement>() as MutableRefObject<HTMLIFrameElement>;
  const draggableHandleRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<ItemPosition | null>(null);
  const [arrowPositionStyle, setArrowPositionStyle] = useState<Pick<CSSProperties, 'left'>>({
    left: ARROW_SIDE_DISTANCE,
  });
  const [isMobileState, setIsMobileState] = useState<boolean>(isMobile());
  const [isDetached, setIsDetached] = useState<boolean | undefined>(undefined);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isClientAppLoaded, setIsClientAppLoaded] = useState<boolean>(false);
  const showWidgetTimer = useRef<ReturnType<typeof setTimeout>>();

  const {
    initParams,
    hasFatalError: hasFatalErrorInitial,
    widget: { size, initialPosition },
  } = getState();
  const { isInvokedFromHeader, anchorSelector, placeholder, origin } = initParams;
  const shouldShowArrow =
    isInvokedFromHeader && anchorSelector && !isDetached && initialPosition !== InitialWidgetPosition.Center;

  const [hasFatalError, setHasFatalError] = useState<boolean>(!!hasFatalErrorInitial);
  const [widgetSize, setWidgetSize] = useState<WidgetSize>(size);

  const isPlaceholderEmbed = !!placeholder?.element;
  const allowedPositionDesktop = useMemo(() => getDraggableAllowedPosition(origin), []);

  const allowedPosition = useMemo(() => {
    return isMobileState ? undefined : allowedPositionDesktop;
  }, [isMobileState, allowedPositionDesktop]);

  const onWindowResize = useCallback(() => {
    const isMobileNow = isMobile();
    if (isMobileState !== isMobileNow) {
      setIsMobileState(isMobileNow);
      publishWidgetMobileViewChanged(isMobileNow);
    }
  }, [isMobileState]);

  useWindowResizeCallback(onWindowResize);

  const handleDragStart = useCallback(() => {
    if (iframeRef.current) {
      // Allowing iframe host window to handle mouse over events
      iframeRef.current.style.pointerEvents = 'none';
    }
    if (isDetached === false) {
      setIsDetached(true);
    }
  }, [iframeRef, isDetached]);

  const handleDragEnd = useCallback(
    ({ left, top, bottom, right }) => {
      if (iframeRef.current) {
        iframeRef.current.style.pointerEvents = 'auto';
      }

      biDragWidget({ left, top, bottom, right });

      if (!isInvokedFromHeader) {
        dispatch('chatbot/updatePosition', { left, top, bottom, right });
      }
    },
    [iframeRef, isInvokedFromHeader],
  );

  const toggleWidget = useCallback(
    (_isVisible: boolean): void => {
      setIsVisible(_isVisible);
      if (!isPlaceholderEmbed) {
        document.body.classList[_isVisible ? 'add' : 'remove'](styles.chatbotWidgetNoscroll);
      }
    },
    [isPlaceholderEmbed],
  );

  const setArrowPosition = useCallback(
    (widgetLeft: number) => {
      if (!shouldShowArrow || !anchorSelector || !draggableRef.current) {
        return;
      }

      const anchorElement = document.querySelector(anchorSelector);

      if (anchorElement) {
        const anchorRect = anchorElement.getBoundingClientRect();
        const anchorXPosition = (anchorRect.right + anchorRect.left) / 2 - ARROW_SIDE_DISTANCE; // reduce 6px to center the arrow
        const minArrowLeft = ARROW_SIDE_DISTANCE; // min distance to the left side
        const maxArrowLeft = draggableRef.current.offsetWidth - ARROW_WIDTH - ARROW_SIDE_DISTANCE; // min distance to the right side
        // limit a number between a min/max value
        const arrowLeft = Math.min(Math.max(anchorXPosition - widgetLeft, minArrowLeft), maxArrowLeft);
        setArrowPositionStyle({ left: arrowLeft });
      }
    },
    [shouldShowArrow],
  );

  const handleWidgetEvent = useCallback(
    (state: State, expanded) => {
      if (!state.widgetIframeSrc && !hasFatalError) {
        toggleWidget(false);
        return;
      }
      if (expanded) {
        // move container to the memoized position on the screen (if needed)
        if (state.chatbot.position && !isPlaceholderEmbed && !isMobile()) {
          setPosition(state.chatbot.position);
        }

        // Making element visible after the draggable child is already positioned
        showWidgetTimer.current = setTimeout(() => {
          toggleWidget(true);
        });
      } else {
        toggleWidget(false);
      }
    },
    [isPlaceholderEmbed, toggleWidget, hasFatalError],
  );

  const onRefresh = (e) => {
    e.stopPropagation();
    if (window.WixSupportChatSDK && initParams) {
      window.WixSupportChatSDK.init(initParams);
      setIsIframeLoaded(false);
      dispatch('app/setHasFatalError', false);

      return;
    }
    window.location.reload();
  };

  useLayoutEffect(() => {
    const appRenderedListener = onStoreEvent('@events/app/render', (state) => {
      handleWidgetEvent(state, state.widget.expanded);
    });
    const widgetExpandedListener = onStoreEvent('@events/widget/expanded', handleWidgetEvent);
    return () => {
      appRenderedListener();
      widgetExpandedListener();
      if (showWidgetTimer.current) {
        clearTimeout(showWidgetTimer.current);
      }
    };
  }, [handleWidgetEvent]);

  useEffect(() => {
    const iframeLoadedUnsubscribe = onStoreEvent('@events/widget/iframeLoaded', () => {
      setIsIframeLoaded(true);
    });
    const clientAppLoadedUnsubscribe = onStoreEvent('@events/app/setClientAppLoaded', () => {
      setIsClientAppLoaded(true);
    });
    const setHasFatalErrorUnsubscribe = onStoreEvent(
      '@events/app/setHasFatalError',
      (_state, isFatalError) => {
        setHasFatalError(isFatalError);
      },
    );
    const setWidgetSizeUnsubscribe = onStoreEvent('widget/setWidgetSize', (_state, value) =>
      setWidgetSize(value),
    );

    return () => {
      iframeLoadedUnsubscribe();
      clientAppLoadedUnsubscribe();
      setHasFatalErrorUnsubscribe();
      setWidgetSizeUnsubscribe();
    };
  }, []);

  useEffect(() => {
    // when position is updated refreshing the isDragged state
    setIsDetached(false);
  }, [position]);

  useEffect(() => {
    const closeByClickHandler = (event: MouseEvent) => {
      if (event.target instanceof Element) {
        const isClickedWithinWidget = event.target.closest(`[data-hook="${HELP_WIDGET}"`);

        const isClickedOnAnchor = anchorSelector && event.target.closest(anchorSelector);

        if (!isClickedWithinWidget && !isClickedOnAnchor) {
          collapseWidget(WidgetClosingTrigger.ClickOutsideWidget);
        }
      }
    };

    if (isInvokedFromHeader && isVisible && !isDetached) {
      document.addEventListener('click', closeByClickHandler);
    }

    return () => {
      document.removeEventListener('click', closeByClickHandler);
    };
  }, [isInvokedFromHeader, anchorSelector, isVisible, isDetached]);

  const classNames = classnames(styles.iframeContainer, {
    [styles.iframeContainerMaximized]: widgetSize === WidgetSize.Maximized,
    [styles.iframeContainerVisible]: isVisible,
  });

  return (
    <Draggable
      ref={draggableRef}
      isDragEnabled={!isPlaceholderEmbed}
      draggableHandleRef={draggableHandleRef}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      position={position}
      allowedPosition={allowedPosition}
      onDraggablePositionSet={setArrowPosition}
      // Important! this data-hook is used in Editor(santa-editor)
      // If this hook is updated please make sure santa-editor tests are also updated
      data-hook={CHATBOT_WIDGET_IFRAME_CONTAINER}
      className={classNames}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
      aria-hidden={!isVisible}
    >
      {shouldShowArrow && (
        <div
          className={
            isClientAppLoaded
              ? styles.iframeContainerHelpWidgetArrowDark
              : styles.iframeContainerHelpWidgetArrowWhite
          }
          style={arrowPositionStyle}
          data-hook={CHATBOT_WIDGET_IFRAME_CONTAINER_ARROW}
        ></div>
      )}
      <div className={styles.overflowContainer}>
        {!hasFatalError && !isIframeLoaded && <Loader />}
        {hasFatalError ? (
          <ErrorScreen onRefresh={onRefresh} />
        ) : (
          <>
            {!isPlaceholderEmbed && <DragIcon ref={draggableHandleRef} isDetached={isDetached} />}
            <WidgetIframe ref={iframeRef} />
          </>
        )}
      </div>
    </Draggable>
  );
});
