import { DEFAULT_LANGUAGE, TSupportLanguage } from '@wix/wix-chatbot-common/locale';
import { i18n, initI18n } from '@wix/wix-i18n-config';

class LocalizationService {
  private _i18n: i18n | undefined;

  get i18n() {
    return this._i18n;
  }

  async init(userLocale: TSupportLanguage = DEFAULT_LANGUAGE): Promise<void> {
    // Skip re-init if locale is the same
    if (this._i18n?.language === userLocale) {
      return;
    }

    this._i18n = initI18n({
      locale: userLocale,
      disableAutoInit: true,
      asyncMessagesLoader: (locale) => import(`../../assets/locales/messages_${locale}.json`),
    });

    await this._i18n.init();
  }
}

export const localizationService = new LocalizationService();
