import { sanitizeHtml } from '@wix/wix-chatbot-common/browser';
import React from 'react';

import s from './styles.scss';

export type TooltipContentProps = Readonly<{
  content: string;
}>;

export const TooltipContent: React.FC<TooltipContentProps> = ({ content }) => {
  const dangerouslySetInnerHTML = React.useMemo(() => {
    return {
      __html: sanitizeHtml(content),
    };
  }, [content]);

  return <div className={s.tooltipContent} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />;
};
