import { SupportChatOrigins } from '@wix/wix-help-widget-common/types';

export const getDraggableAllowedPosition = (origin: SupportChatOrigins) => {
  const padding = 12;
  // Use real element rect in future
  const headerHeight = origin === 'Landing' ? 72 : 48;

  return {
    left: padding,
    right: padding,
    top: padding + headerHeight,
    bottom: padding,
  };
};

export const getWidgetPositionByAnchorSelector = (
  selector: string,
  origin: SupportChatOrigins,
): { x: number; y: number } | null => {
  const anchorElement = document.querySelector(selector);
  const arrowToLeftDistance = 18;
  const anchorMargin = 12;

  const allowedRect = getDraggableAllowedPosition(origin);

  if (anchorElement) {
    const anchorRect = anchorElement.getBoundingClientRect();
    return {
      // To position the widget correctly we should shift it to the left by a value defined by css style
      x: (anchorRect.right + anchorRect.left) / 2 - arrowToLeftDistance,
      // Making sure the position is not upper than allowed by Y
      y: Math.max(allowedRect.top, anchorRect.bottom + anchorMargin),
    };
  }

  return null;
};
