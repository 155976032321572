import React, { useCallback } from 'react';
import { CTA, CTAType, TooltipRenderData } from '../../types';
import s from './styles.scss';
import { Button, Box } from '@wix/design-system';
import * as Icons from '@wix/wix-ui-icons-common';
import classnames from 'classnames';

export type TooltipCTAProps = Readonly<{
  cta: TooltipRenderData['cta'];
  onDismissClick: (e: React.MouseEvent, cta: CTA) => void;
  onSubmitClick: (e: React.MouseEvent, cta: CTA) => void;
}>;

const ctaTypeClass: Record<CTAType, string> = {
  [CTAType.PRIMARY]: s.tooltipCtaPrimary,
  [CTAType.OUTLINED]: '',
  [CTAType.PLAIN]: '',
};

const getCtaTypeClass = (ctaType?: CTAType): string => {
  if (!ctaType) {
    return '';
  }

  return classnames(s.tooltipCTA, ctaTypeClass[ctaType]);
};

export const TooltipCTA = ({ cta, onSubmitClick, onDismissClick }: TooltipCTAProps) => {
  if (!cta) {
    return null;
  }

  return Array.isArray(cta) ? (
    <Box direction="horizontal" gap="6px">
      {cta.map((ctaItem) => (
        <TooltipCTAButton cta={ctaItem} onDismissClick={onDismissClick} onSubmitClick={onSubmitClick} />
      ))}
    </Box>
  ) : (
    <TooltipCTAButton cta={cta} onDismissClick={onDismissClick} onSubmitClick={onSubmitClick} />
  );
};

type TooltipCTAButtonProps = {
  onDismissClick(e: React.MouseEvent, cta: CTA): void;
  onSubmitClick(e: React.MouseEvent, cta: CTA): void;
  cta: CTA;
};

const TooltipCTAButton = ({ cta, onDismissClick, onSubmitClick }: TooltipCTAButtonProps) => {
  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (cta.shouldDismissTooltip) {
        onDismissClick(e, cta);
      } else {
        onSubmitClick(e, cta);
      }
    },
    [cta, onDismissClick, onSubmitClick],
  );

  return (
    <Button
      size="small"
      key={cta.key}
      priority={cta.type === CTAType.PRIMARY ? 'primary' : 'secondary'}
      skin="light"
      suffixIcon={cta.shouldDismissTooltip ? undefined : <Icons.ChevronRight />}
      className={getCtaTypeClass(cta?.type)}
      onClick={clickHandler}
    >
      {cta?.key}
    </Button>
  );
};
