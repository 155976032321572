import React, { FC } from 'react';

export const ErrorIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
    <path
      d="M59.38 33.12L73 9L79.38 30.61L106 12L88.12 46.88L115.62 60.62L89.38 71.88L99.98 102.82L70.11 82.49L63.12 115.62L48.12 86.88L13.12 105.62L30.62 69.38L4 66L26.88 51.88L10 25L47 41L41 4L59.38 33.12Z"
      fill="#FFB6AC"
    />
    <path
      d="M47.14 45.4302L39.06 48.7302C34.79 50.4702 32.35 54.7802 32.78 59.1502L27.56 61.2802C24.27 62.6202 22.7 66.3702 24.04 69.6602L26.88 76.6102C28.22 79.9002 31.97 81.4702 35.26 80.1302L40.48 78.0002C43.23 81.4202 47.99 82.7902 52.26 81.0402L60.34 77.7402L47.14 45.4302Z"
      fill="white"
    />
    <path
      d="M102.46 46.37L99.4 39.51C97.95 36.27 94.16 34.81 90.91 36.26L85.76 38.55C82.9 35.22 78.1 34 73.89 35.88L65.92 39.43L80.13 71.31L88.1 67.76C92.31 65.88 94.62 61.5 94.05 57.15L99.2 54.86C102.44 53.42 103.9 49.62 102.45 46.37H102.46Z"
      fill="white"
    />
    <path
      d="M87.5 110C98.8218 110 108 100.822 108 89.5C108 78.1782 98.8218 69 87.5 69C76.1782 69 67 78.1782 67 89.5C67 100.822 76.1782 110 87.5 110Z"
      fill="#D72E15"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.67 95.8101C86.48 95.8101 85.47 96.7801 85.43 97.9701C85.39 99.1601 86.32 100.13 87.52 100.13C88.72 100.13 89.71 99.1601 89.76 97.9701C89.8 96.7801 88.87 95.8101 87.67 95.8101Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.3 77.3701C86.76 77.3701 85.5 78.6601 85.55 80.1801L85.96 91.9901H89.59L90.85 80.1801C91.01 78.6601 89.84 77.3701 88.3 77.3701Z"
      fill="white"
    />
    <path
      d="M60.84 53.9802L52.09 57.5602L50.44 53.5202L59.19 49.9402C60.31 49.4802 61.58 50.0202 62.03 51.1302C62.49 52.2502 61.95 53.5202 60.84 53.9702V53.9802ZM66.43 61.9102C65.97 60.7902 64.7 60.2602 63.59 60.7202L54.84 64.3002L56.49 68.3402L65.24 64.7602C66.36 64.3002 66.89 63.0302 66.43 61.9202V61.9102ZM112.59 25.7502C110 22.6002 105.18 21.5402 100.26 22.9202L99.62 24.1702C104.48 22.4902 109.32 23.3502 111.82 26.3802C112.47 27.1702 113.93 29.3102 113.56 32.2102C113.15 35.4302 110.69 37.3002 109.65 37.9602C108.82 37.1102 107.53 36.8202 106.39 37.3302L99.75 40.2902L102.12 45.6002L108.76 42.6402C110.23 41.9902 110.89 40.2702 110.23 38.8002C111.43 38.0302 114.1 35.9502 114.57 32.3402C114.98 29.0602 113.33 26.6502 112.6 25.7602L112.59 25.7502ZM12.54 85.7702C11.58 83.2202 12.46 80.2902 14.79 78.2902C15.23 77.9102 15.71 77.5902 16.22 77.3102C16.93 78.5702 18.47 79.1402 19.84 78.5802L26.57 75.8302L24.37 70.4402L17.64 73.1902C16.36 73.7102 15.66 75.0402 15.87 76.3502C15.25 76.6802 14.66 77.0602 14.13 77.5202C11.48 79.7902 10.49 83.1702 11.6 86.1102C12.57 88.6902 15.83 90.3502 18.46 90.8002L20.29 91.0402L20.76 90.0702L18.61 89.8202C16.2 89.4002 13.33 87.8702 12.54 85.7602V85.7702Z"
      fill="#192C55"
    />
    <path
      d="M40.51 78.0399L40.12 78.2099L39.62 78.4199L31.89 59.4899L32.39 59.2799L32.78 59.1099L40.51 78.0399Z"
      fill="#FFB6AC"
    />
    <path
      d="M94.94 56.7998L94.56 56.9698L94.06 57.1998L85.85 38.4798L86.34 38.2498L86.72 38.0698L94.94 56.7998Z"
      fill="#FFB6AC"
    />
  </svg>
);
