import { MESSAGE_MARK_AS_READ } from '../constants';
import { httpClient } from '../utils';

let timeoutId = 0;

export const markAsRead = async (conversationId?: string, lastMessageId?: string): Promise<void> => {
  if (!conversationId || !lastMessageId) {
    return;
  }
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = 0;
  }
  timeoutId = window.setTimeout(() => {
    const httpClientInstance = httpClient.getInstance();
    httpClientInstance.put(MESSAGE_MARK_AS_READ, {
      lastMessageId,
      conversationId,
    });
    timeoutId = 0;
  }, 300);
};
