type UrlChangeListener = (cb: () => void) => void;
type HistoryFnName = 'pushState' | 'replaceState';

const getPathName = (url?: string | URL | null): string | undefined => {
  if (url instanceof URL) {
    return url.pathname;
  }
  if (!url) {
    return undefined;
  }
  try {
    return new URL(url).pathname;
  } catch (e) {
    return url?.split('?')[0];
  }
};
/**
 * each listener is being invoked every url (href) change
 * In our case we want to invoke the callback only when the pathname changes.
 */
const createUrlChangeListener = (fnName: HistoryFnName): UrlChangeListener => {
  return (callback) => {
    const oldHandler = window.history[fnName];
    window.history[fnName] = (...args) => {
      // an optional url arg. https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
      const newUrl = args[2];
      const pathname = getPathName(newUrl);
      const isSamePath = pathname === window.location.pathname;
      oldHandler.apply(window.history, args);
      if (pathname && !isSamePath) {
        callback();
      }
    };
  };
};

export const onPushState: UrlChangeListener = createUrlChangeListener('pushState');
export const onReplaceState: UrlChangeListener = createUrlChangeListener('replaceState');

export const onPopState: UrlChangeListener = (callback) => {
  const originalPopStateHandler = window.onpopstate;
  window.onpopstate = (ev: PopStateEvent) => {
    if (typeof originalPopStateHandler === 'function') {
      originalPopStateHandler.call(window, ev);
    }
    callback();
  };
};
