import { HttpClient } from '@wix/http-client';
import { TSupportLanguage } from '@wix/wix-chatbot-common/locale';
import { Brand, SupportChatOrigins } from '@wix/wix-help-widget-common/types';
import { Experiments, WidgetSpecs } from '@wix/wix-help-widget-common/experiments';
import { WIDGET_MODEL_URL } from '../constants';
import { WidgetDataRequestFailure } from '../errors';

export interface WidgetRenderModel {
  config: {
    duplexer: {
      duplexerURL: string;
      messageReadDelay: number;
      messageChannels: {
        chatbotMessage: string;
        widgetSession: string;
      };
    };
  };
  locale: TSupportLanguage;
  brand: Brand;
  experiments?: WidgetSpecs;
  authorizationToken?: string;
}

type WidgetModelArgs = {
  httpClient: HttpClient;
  origin: SupportChatOrigins;
};

class WidgetModel {
  private httpClient: HttpClient;
  private origin: SupportChatOrigins;
  private initialize: boolean = false;
  private rawModel?: WidgetRenderModel;
  private experiments: Experiments<WidgetSpecs>;

  async init({ httpClient, origin }: WidgetModelArgs) {
    this.httpClient = httpClient;
    this.origin = origin;
    await this.fetchModel();
    this.initialize = true;
  }

  private async fetchModel(): Promise<WidgetRenderModel> {
    const { data } = await this.httpClient.post(WIDGET_MODEL_URL, { origin: this.origin });
    if (data.error) {
      throw new WidgetDataRequestFailure(data.error.message, 'widgetModel');
    }
    this.rawModel = data;
    this.experiments = new Experiments<WidgetSpecs>(data?.experiments);
    return data;
  }

  refetchModel(): Promise<WidgetRenderModel> {
    return this.fetchModel();
  }

  getModel() {
    if (!this.initialize) {
      throw new Error('Widget model must be initialize first');
    }
    return {
      ...this.rawModel,
      experiments: this.experiments,
    };
  }

  getExperiments() {
    if (!this.initialize) {
      throw new Error('Widget model must be initialize first');
    }
    return this.experiments;
  }

  getConfig() {
    if (!this.initialize) {
      throw new Error('Widget model must be initialize first');
    }
    return this.rawModel?.config;
  }

  getLocale() {
    if (!this.initialize) {
      throw new Error('Widget model must be initialize first');
    }
    return this.rawModel?.locale;
  }

  getBrand() {
    if (!this.initialize) {
      throw new Error('Widget model must be initialize first');
    }
    return this.rawModel?.brand;
  }
}

export const widgetModel = new WidgetModel();
