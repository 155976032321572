import { dispatch, onStoreEvent, State } from '../../store';
import { isMobile } from '../../utils';
import { wixSupportChatSDKEmitUpdateWidget } from '../../components/common';

/**
 *
 * @return shouldExpandWidget indicator
 * if response type is a boolean - we want to dispatch widget expand action. that means expand is changing
 * if response is null - we don't want to dispatch widget expand action. that means expand remain the same value.
 */
const shouldExpandWidget = (state: State): boolean | null => {
  const { chatbot, initParams, widget, widgetIframeSrc } = state;
  const forceExpanded = initParams.placeholder?.element;
  const widgetExpanded = widget?.expanded;
  const dealerIsExpanded = chatbot.dealerOffer?.payload.chatOpened;
  const isInvokedFromHeader = initParams.isInvokedFromHeader;
  // full page
  if (forceExpanded) {
    return true;
  }
  // no start url (specific pages we don't want to show widget)
  if (!widgetIframeSrc) {
    return false;
  }
  // when navigating between pages in the same app
  if (widget.rendered) {
    return null;
  }
  if (chatbot.isChatbotSessionActive) {
    return widgetExpanded ?? (!isMobile() && !isInvokedFromHeader);
  }
  // dispatch based on dealer offer (only for desktop)
  return widgetExpanded ?? (!isMobile() && Boolean(dealerIsExpanded) && !isInvokedFromHeader);
};

export const subscribeToAppRender = () => {
  onStoreEvent('@events/app/render', (state) => {
    const expandWidget = shouldExpandWidget(state);
    if (expandWidget != null) {
      wixSupportChatSDKEmitUpdateWidget(expandWidget, state.chatbot.conversation.unreadCount);
      dispatch('widget/expanded', expandWidget);
    }
    dispatch('tooltip/update');
  });

  onStoreEvent('@events/app/render', () => {
    window.WixSupportChatSDK.emit('appRenderComplete');
  });
};
