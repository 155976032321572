import type { WidgetStateListener } from '@wix/wix-chatbot-common/browser';
import TypedEventEmitter from 'typed-emitter';

export enum WIDGET_LOAD_FAIL_PHASE {
  STATE_LOADING = 'WIDGET_STATE_LOADING_ISSUE',
}

type WidgetSdkEvents = {
  appRenderComplete: () => void;
  initComplete: () => void;
  updateWidgetState: WidgetStateListener;
};

export type WidgetSdkEventEmitter = TypedEventEmitter<WidgetSdkEvents>;
