import { useEffect } from 'react';
import { onStoreEvent } from '../store';
import { chatbotNotificationReceived } from '../bi';

/**
 * Responsible for sending BI events when unread messages appear
 */
export const useUnreadMessagesSubscriber = () => {
  useEffect(() => {
    const subscription = onStoreEvent('@events/chatbot/unreadCount', ({ widget }, unreadCount: number) => {
      if (!widget.expanded && unreadCount > 0) {
        chatbotNotificationReceived({ counterValue: unreadCount });
      }
    });

    return () => {
      subscription();
    };
  }, []);
};
