import { OpenHelpWidgetArticleParams, WidgetSize } from '@wix/wix-chatbot-common/browser';
import { WIDGET_URL } from '../../constants';
import { dispatch, getState } from '../../store';
import { publishNewArticleId, publishNewArticleUri } from '../../events/publish/widgetChannel';
import { buildWidgetUrl } from '../../widget/startUrl';
import { clientRoutes, SupportedClientRoute } from '../../widget/clientRoutes';
import { openWidget } from './openWidget';
import { InitialWidgetPosition } from '../../store/modules/widget';

const getClientRoute = ({
  articleId,
  articleUri,
}: Pick<OpenHelpWidgetArticleParams, 'articleId' | 'articleUri'>) => {
  if (articleId) {
    return clientRoutes.articleById(articleId);
  } else if (articleUri) {
    return clientRoutes.articleByUri(articleUri);
  } else {
    throw new Error('Invalid params. `articleUri` or `articleId` should be presented');
  }
};

export function openHelpWidgetArticle(params: OpenHelpWidgetArticleParams) {
  const clientRoute = getClientRoute(params);
  const { widgetIframeSrc } = getState();
  // if no dealer offer and no dealer session old url will be empty string
  const currentUrl = widgetIframeSrc ? widgetIframeSrc : WIDGET_URL;
  const newUrl = buildWidgetUrl(currentUrl, undefined, clientRoute);

  if (clientRoute.route === SupportedClientRoute.ArticleById) {
    publishNewArticleId(clientRoute.article);
  } else if (clientRoute.route === SupportedClientRoute.ArticleByUri) {
    publishNewArticleUri(clientRoute.article);
  }

  dispatch('app/setWidgetIframeSrc', newUrl);

  if (params.centerPosition) {
    // TODO: move dispatching `widget/setWidgetSize` out of `if (centerPosition)` when `specs.helpwidget.widgetMaximization` is merged
    // for now let's simplify it and do not conduct this spec on widget side
    dispatch('widget/setWidgetSize', WidgetSize.Maximized);
    dispatch('widget/setInitialPosition', InitialWidgetPosition.Center);
  }

  if (params.biData?.placement !== undefined) {
    dispatch('bi/setWidgetPlacement', params.biData.placement);
  }

  openWidget();
}
