import { MOBILE_MEDIA_QUERY } from '../constants';

export const noop = (): void => {
  return;
};

export const isNumber = (val: unknown): val is number => {
  return typeof val === 'number';
};

export const isString = (val: unknown): val is string => {
  return typeof val === 'string';
};

export const isElement = (val: unknown): val is Element => {
  return val instanceof Element;
};

// Type guard for `undefined` or `null` values
export function isNonNullable<T>(x: T | undefined | null): x is NonNullable<T> {
  // quick comparison with both undefined and null
  return x != null;
}

export function assertExists<T>(x: T, errorMessage: string): asserts x is NonNullable<T> {
  // quick comparison with both undefined and null
  if (x == null) {
    throw new Error(`assertExists: "${errorMessage}"`);
  }
}

export function assertUnreachable(x: never): never {
  throw new Error(`Didn't expect to get here. Value: ${x}`);
}

export function a11yFocus(node: HTMLElement | null): void {
  setTimeout(() => {
    node?.focus();
  }, 50);
}

export const isMobile = (): boolean => window.matchMedia(MOBILE_MEDIA_QUERY).matches;

export * from './httpClient';
