import type { DealerOfferDetails } from '../types';
import { isWorkingHours } from './isWorkingHours';

export const getOfferParams = (offer?: DealerOfferDetails): Record<string, string> | undefined => {
  if (!offer?.payload) {
    return undefined;
  }

  const { interactionId, chatbotConfig } = offer.payload;

  if (!chatbotConfig.value || !isWorkingHours(offer.payload)) {
    return undefined;
  }

  const url = new URL(chatbotConfig.value);
  const offerUrlParams = Object.fromEntries(url.searchParams);

  if (interactionId) {
    offerUrlParams.interactionId = interactionId;
  }

  return offerUrlParams;
};
