import { State } from '../../store';
import { TooltipRenderDataWithTimeout, TooltipType } from '../../types';
import { getTooltipFromOffer } from '../../dealer';

type Tooltip = {
  payload: TooltipRenderDataWithTimeout;
  type: TooltipType;
};

export function getTooltip({ chatbot }: State): Tooltip | undefined {
  const { dealerOffer } = chatbot;
  const offerPayload = getTooltipFromOffer(dealerOffer);
  if (offerPayload) {
    return {
      payload: offerPayload,
      type: TooltipType.DealerOffer,
    };
  }
  return undefined;
}
