import { CHATBOT_PAGE_URL } from '../constants';
import { WidgetState } from '../store/modules/widget';

/**
 * Returns true if notifications should be cleared.
 * If the widget is open, active and on chatbot page should clear all notifications.
 * If the widget is open, active and this is a new conversation (first render - new session) should clear all
 * notifications.
 */
export const shouldClearNotifications = (
  widget: WidgetState['widget'],
  isNewConversation: boolean,
  isTabActive: boolean,
): boolean => {
  const { expanded, currentUrl } = widget;
  const isChatbotPage = currentUrl === CHATBOT_PAGE_URL;
  if (expanded && isTabActive && (isChatbotPage || isNewConversation)) {
    return true;
  }

  return false;
};
