import React, { useCallback, useLayoutEffect, useMemo, useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { CHATBOT_WIDGET_TOOLTIP_CLOSE_ICON, CHATBOT_WIDGET_TOOLTIP_CONTAINER } from '../../constants';
import { dispatch, getState, onStoreEvent } from '../../store';
import { hwPublishLog, LOG_EVENTS } from '../../events/publish/widgetChannel';
import { biTooltipClick, biTooltipClose, biTooltipLoad, tooltipButtonClick } from '../../bi';
import { CTA, TooltipRenderData, TooltipType } from '../../types';
import { expandWidget } from '../../sdk/methods';
import { getWidgetPositionByAnchorSelector } from '../../utils/position';
import { cancelSchedule, scheduleOpen } from './scheduleOpen';
import { WidgetTooltipContent } from './WidgetTooltipContent';
import s from './styles.scss';
import { getStartUrlFromDealerOffer } from '../../widget/startUrl';
import { clientRoutes } from '../../widget/clientRoutes';
import * as Icons from '@wix/wix-ui-icons-common';
import { Box, IconButton } from '@wix/design-system';
import { getTooltip } from './getTooltip';
import { TooltipBiButtonId } from './constants';

import '@wix/design-system/styles.global.css';

export const WidgetTooltip: React.FC = () => {
  const containerRef = useRef(null);
  const [openedTooltipType, setOpenedTooltipType] = useState<TooltipType | undefined>();
  const [isVisible, setIsVisible] = useState(false);
  const [closeTimer, setCloseTimer] = useState<ReturnType<typeof setTimeout> | undefined>();
  const [tooltipPositionStyle, setTooltipPositionStyle] = useState<{
    left: number;
    top: number;
  }>();
  const [widgetTooltipData, setWidgetTooltipData] = useState<TooltipRenderData>();
  useEffect(() => {
    return () => {
      clearTimeout(closeTimer);
    };
  }, [closeTimer]);

  useLayoutEffect(() => {
    const tooltipOpenSubscription = onStoreEvent('@events/tooltip/open', (state) => {
      clearTimeout(closeTimer);

      const tooltip = getTooltip(state);

      if (!tooltip) {
        hwPublishLog(LOG_EVENTS.TOOLTIP_OPEN_MISSING_PAYLOAD, {
          dealerOffer: state.chatbot.dealerOffer,
          tooltip: state.tooltip,
        });
        return;
      }

      showTooltip(tooltip.type, tooltip.payload);
      biTooltipLoad(tooltip.payload);

      if (tooltip.payload.closeAfterXSeconds) {
        scheduleClose(Number(tooltip.payload.closeAfterXSeconds));
      }
    });

    const tooltipExpandedSubscription = onStoreEvent('@events/widget/expanded', (_, expanded) => {
      // check if previous state is open(to prevent initial events)
      if (expanded) {
        hideTooltip();
        cancelSchedule();
      }
    });

    // fired on navigation, after we fetched all data and updated the state
    const tooltipUpdatedSubscription = onStoreEvent('@events/tooltip/update', (state) => {
      const tooltip = getTooltip(state);

      if (tooltip) {
        scheduleOpen(Number(tooltip.payload.appearanceAfterXSeconds));
      } else {
        hideTooltip();
      }
    });

    const pageNavigateSubscription = onStoreEvent('@events/app/pageNavigate', () => {
      cancelSchedule();
      hideTooltip();
    });

    return () => {
      tooltipOpenSubscription();
      tooltipExpandedSubscription();
      tooltipUpdatedSubscription();
      pageNavigateSubscription();
    };
  }, []);

  const handleTooltipAction = useCallback(() => {
    const {
      chatbot: { dealerOffer },
    } = getState();
    if (!widgetTooltipData) {
      hwPublishLog(LOG_EVENTS.TOOLTIP_CLICK_MISSING_PAYLOAD, {
        dealerOffer,
        tooltip: getState().tooltip,
      });
      return;
    }
    hideTooltip();

    // Extra actions by tooltip type besides just hiding tooltip and expanding widget
    switch (openedTooltipType) {
      case TooltipType.DealerOffer:
        const maybeDealerOfferStartUrl = getStartUrlFromDealerOffer({
          dealerOffer,
          predefinedClientRoute: clientRoutes.chatbot(),
        });
        if (maybeDealerOfferStartUrl) {
          dispatch('app/setWidgetIframeSrc', maybeDealerOfferStartUrl);
        }
        expandWidget();
        break;
    }
  }, [openedTooltipType, widgetTooltipData]);

  const biCTAClick = useCallback(
    (cta: CTA) => {
      const payload = widgetTooltipData;
      if (!payload) {
        return;
      }
      // USP tooltip click handled in onClickUspTooltip
      switch (openedTooltipType) {
        case TooltipType.DealerOffer:
          tooltipButtonClick({
            buttonId: TooltipBiButtonId.DealerOfferTooltipCTA,
            buttonName: cta.key,
            tooltipName: payload.title,
            tooltipText: payload.content || '',
          });
          break;
      }
    },
    [openedTooltipType, widgetTooltipData],
  );

  const onSubmitClick = useCallback(
    (e: React.MouseEvent, cta: CTA) => {
      // prevent propagate to tooltip container (to avoid calling onClick)
      e.stopPropagation();

      biCTAClick(cta);
      handleTooltipAction();
    },
    [handleTooltipAction, biCTAClick],
  );

  const onContainerClick = (): void => {
    if (widgetTooltipData) {
      biTooltipClick(widgetTooltipData);
    }
    handleTooltipAction();
  };

  const hideTooltip = (): void => {
    clearTimeout(closeTimer);
    setOpenedTooltipType(undefined);
    setIsVisible(false);
  };

  const scheduleClose = (seconds: number) => {
    const timer = setTimeout(() => {
      dispatch('tooltip/close-per-type', openedTooltipType);
      hideTooltip();
    }, seconds * 1000);

    setCloseTimer(timer);
  };

  const onCloseIconClick = (e: React.MouseEvent): void => {
    // prevent propagate to tooltip container(to avoid calling onClick)
    e.stopPropagation();

    const state = getState();
    const {
      chatbot: { dealerOffer },
    } = state;
    const payload = widgetTooltipData;

    if (!payload) {
      hwPublishLog(LOG_EVENTS.TOOLTIP_CLICK_MISSING_PAYLOAD, {
        dealerOffer,
        tooltip: state.tooltip,
      });
      return;
    }
    dispatch('tooltip/close-per-type', openedTooltipType);
    hideTooltip();
    biTooltipClose(payload);
  };

  const onDismissClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    dispatch('tooltip/close-per-type', openedTooltipType);
    hideTooltip();
  };

  const showTooltip = (type: TooltipType, { title, content, cta, intent }: TooltipRenderData): void => {
    // close previous tooltip before opening new one
    if (openedTooltipType !== undefined) {
      hideTooltip();
    }

    const {
      initParams: { anchorSelector, origin },
    } = getState();

    // anchor selector - is actually dom element selector
    if (anchorSelector) {
      // the position of tooltip should be the same as widget initial position
      const position = getWidgetPositionByAnchorSelector(anchorSelector, origin);

      setTooltipPositionStyle(
        position
          ? {
              left: position.x,
              top: position.y,
            }
          : undefined,
      );
    }

    setWidgetTooltipData({ title, content, cta, intent });
    setIsVisible(true);
    setOpenedTooltipType(type);
  };

  const containerClassName = useMemo(() => {
    const classList = [s.tooltipContainer];

    if (tooltipPositionStyle) {
      classList.push(s.tooltipContainerWithArrow);
    }

    if (openedTooltipType === TooltipType.DealerOffer) {
      classList.push(s.tooltipContainerPointer);
    }

    if (isVisible) {
      classList.push(s.tooltipContainerVisible);
    }

    return classnames(...classList);
  }, [openedTooltipType, tooltipPositionStyle, isVisible]);

  return (
    <div
      ref={containerRef.current}
      className={containerClassName}
      style={tooltipPositionStyle}
      aria-hidden={!isVisible}
      data-hook={CHATBOT_WIDGET_TOOLTIP_CONTAINER}
      onClick={onContainerClick}
    >
      {isVisible && widgetTooltipData && (
        <Box width="100%" direction="vertical">
          <WidgetTooltipContent
            {...widgetTooltipData}
            onSubmitClick={onSubmitClick}
            onDismissClick={onDismissClick}
          />
          <IconButton
            onClick={onCloseIconClick}
            dataHook={CHATBOT_WIDGET_TOOLTIP_CLOSE_ICON}
            size="tiny"
            skin="transparent"
            priority="secondary"
            className={s.closeButton}
          >
            <Icons.X />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
