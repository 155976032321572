import React, { useEffect, useState } from 'react';
import { NOTIFICATIONS_COUNT } from '../../constants';
import { onStoreEvent } from '../../store';

import s from './styles.scss';

export const Counter: React.FC = () => {
  const [count, setCount] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const subscription = onStoreEvent('@events/chatbot/unreadCount', (_, unreadCount: number) => {
      setCount(unreadCount);
      setIsEmpty(unreadCount < 1);
    });

    return () => {
      subscription();
    };
  }, []);

  return (
    <div
      style={{ display: isEmpty ? 'none' : 'flex' }}
      aria-valuenow={count}
      aria-valuetext={`${count} unread messages`}
      aria-hidden={isEmpty}
      className={s.counter}
      role="progressbar"
      data-hook={NOTIFICATIONS_COUNT}
    >
      {count}
    </div>
  );
};
