import { httpClient } from '../utils';
import { fedopsLogger } from '../fedops';
import { CHATBOT_OFFER_URL } from '../constants';
import { getOfferData, DealerOfferDetails } from '../dealer';
import { DealerRequestFailure } from '../errors';
import { dispatch } from '../store';
import { DataFetcher } from './types';

type DealerOfferParams = {
  origin: string;
};

export class DealerOfferService implements DataFetcher<DealerOfferDetails> {
  private origin: string;
  private dealerOfferDetails: DealerOfferDetails | undefined;
  private isOfferFetched = false;

  constructor(params: DealerOfferParams) {
    this.origin = params.origin;
  }

  async get(): Promise<DealerOfferDetails | undefined> {
    if (!this.isOfferFetched) {
      await this.fetch();
    }
    return this.dealerOfferDetails;
  }

  async fetch(): Promise<DealerOfferDetails | undefined> {
    fedopsLogger.interactionStarted('fetching-dealer-offer');
    const { data } = await httpClient.getInstance().post(CHATBOT_OFFER_URL, {
      origin: this.origin,
      url: window.location.href,
    });
    const dealerOffer = getOfferData(data?.dealerOffers?.offers[0]);
    fedopsLogger.interactionEnded('fetching-dealer-offer');

    this.isOfferFetched = true;

    if (data.error) {
      throw new DealerRequestFailure(data.error.message, 'fetchDealerOffer');
    }
    dispatch('chatbot/setDealerOffer', dealerOffer);

    this.dealerOfferDetails = dealerOffer;

    return dealerOffer;
  }
}
