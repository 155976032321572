import { WidgetRuntimeState } from '@wix/wix-help-widget-common/types';
import { dispatch, getState, onStoreEvent } from '../store';
import { assertExists, httpClient } from '../utils';
import { fedopsLogger } from '../fedops';
import { WIDGET_RUNTIME_DATA_URL } from '../constants';
import { WidgetDataRequestFailure } from '../errors';

export class WidgetRuntimeService {
  private widgetRuntimeState?: Promise<WidgetRuntimeState>;

  constructor() {
    onStoreEvent('@events/widget/iframeSrcSet', async () => {
      if (this.widgetRuntimeState === undefined) {
        this.widgetRuntimeState = this.fetch();
      }
    });
    onStoreEvent('@event/app/sessionExpired', () => {
      this.widgetRuntimeState = undefined;
    });
  }

  async get(): Promise<WidgetRuntimeState> {
    if (this.widgetRuntimeState === undefined) {
      this.widgetRuntimeState = this.fetch();
    }
    return this.widgetRuntimeState;
  }

  refresh(): void {
    this.widgetRuntimeState = this.fetch();
  }

  private async fetch(): Promise<WidgetRuntimeState> {
    try {
      const { widgetIframeSrc } = getState();

      assertExists(widgetIframeSrc, 'widgetIframeSrc should be defined to call runtime data');

      fedopsLogger.interactionStarted('fetching-widget-runtime-state');
      const { data } = await httpClient.getInstance().post(WIDGET_RUNTIME_DATA_URL, {});
      fedopsLogger.interactionEnded('fetching-widget-runtime-state');
      // we are dispatching new widget session id in case of session expired
      // TODO: add types
      dispatch('app/setData', {
        widgetSessionId: data.widgetSession?.sessionId,
      });

      if (data.error) {
        throw new WidgetDataRequestFailure(data.error.message, 'getRuntimeState');
      }
      return data;
    } catch (e) {
      dispatch('app/setHasFatalError', true);
      throw new WidgetDataRequestFailure(e.message, 'getRuntimeState');
    }
  }
}
