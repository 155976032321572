import { OpenHelpWidgetSearchParams } from '@wix/wix-chatbot-common/browser';
import { isString } from '../../utils';
import { WIDGET_URL } from '../../constants';
import { dispatch, getState } from '../../store';
import { publishOpenSearchPage } from '../../events/publish/widgetChannel';
import { buildWidgetUrl } from '../../widget/startUrl';
import { clientRoutes } from '../../widget/clientRoutes';
import { openWidget } from './openWidget';

export function openHelpWidgetSearch({ query }: OpenHelpWidgetSearchParams) {
  if (!isString(query)) {
    throw new Error('Invalid query search params');
  }

  const { widgetIframeSrc } = getState();
  // if no dealer offer and no dealer session old url will be empty string
  const currentUrl = widgetIframeSrc ? widgetIframeSrc : WIDGET_URL;
  const clientRoute = clientRoutes.search(query);
  const newUrl = buildWidgetUrl(currentUrl, undefined, clientRoute);

  publishOpenSearchPage(query);
  dispatch('app/setWidgetIframeSrc', newUrl);

  openWidget();
}
