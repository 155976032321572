import { dateExpired } from '@wix/wix-chatbot-common/browser';
import { TooltipData, TooltipType, TooltipPerOffer, TooltipPerType } from '@wix/wix-help-widget-common/types';
import { StoreonModule } from 'storeon';
import { Events, State } from '..';
import { DealerOfferDetails } from '../../dealer/types';
import { insertItem, isEqual } from '../util';
import { TooltipType as InternalTypes } from '../../types';

export const tooltipTypesMapping: Record<InternalTypes, TooltipType> = {
  [InternalTypes.DealerOffer]: TooltipType.COMMON,
};

export type TooltipState = TooltipData & {
  isOpened: boolean;
};

export type TooltipReducer = {
  tooltip: TooltipState;
};

export type TooltipEvents = {
  'tooltip/open': never;
  'tooltip/close': never;
  'tooltip/close-per-offer': Omit<DealerOfferDetails, 'payload'>;
  'tooltip/close-per-type': InternalTypes;
  'tooltip/update': never;

  '@events/tooltip/update': TooltipState;
  '@events/tooltip/open': never;
};

export const tooltipModule: StoreonModule<State, Events> = ({ on, dispatch }) => {
  on('@init', () => ({
    tooltip: {
      isOpened: false,
      payload: null,
    },
  }));

  on('tooltip/open', (oldState) => {
    dispatch('@events/tooltip/open');

    return {
      tooltip: {
        ...oldState.tooltip,
        isOpened: true,
      },
    };
  });

  on('tooltip/close', (oldState) => {
    return {
      tooltip: {
        ...oldState.tooltip,
        isOpened: false,
      },
    };
  });

  on('tooltip/close-per-type', (oldState, type) => {
    const perType = oldState.tooltip?.perType || [];
    const item = {
      closedAt: Math.round(Date.now() / 1000),
      type: tooltipTypesMapping[type] || TooltipType.UNKNOWN,
    };

    return {
      tooltip: {
        ...oldState.tooltip,
        isOpened: false,
        perType: [...perType, item],
      },
      __dataCapsule: Date.now(),
    };
  });

  on('tooltip/close-per-offer', (oldState, offer) => {
    const perOffer = oldState.tooltip?.perOffer || [];
    const item = {
      closedAt: Math.round(Date.now() / 1000),
      offerGuid: offer.guid,
    };

    return {
      tooltip: {
        ...oldState.tooltip,
        isOpened: false,
        perOffer: insertItem(perOffer, { index: perOffer.length, item }),
      },
      __dataCapsule: Date.now(),
    };
  });

  // remove expired closedAt timestamps from state, and return updated state
  // emit '@events/tooltip/update' if state changed
  on('tooltip/update', (oldState) => {
    const tooltip = { ...oldState.tooltip };
    const { tooltipCloseTimeout = 0 } = oldState.initParams;

    const checkTooltipExpired = (state: TooltipPerType | TooltipPerOffer) => {
      return (
        !dateExpired(state.closedAt * 1000, tooltipCloseTimeout) && {
          ...state,
        }
      );
    };

    // go through perType entries and delete expired ones
    if (Array.isArray(tooltip.perType)) {
      tooltip.perType = tooltip.perType.map(checkTooltipExpired).filter(Boolean) as TooltipPerType[];

      if (tooltip.perType.length === 0) {
        delete tooltip.perType;
      }
    }

    // then go through perOffer entries and delete expired ones
    if (Array.isArray(tooltip.perOffer)) {
      tooltip.perOffer = tooltip.perOffer.map(checkTooltipExpired).filter(Boolean) as TooltipPerOffer[];

      if (tooltip.perOffer.length === 0) {
        delete tooltip.perOffer;
      }
    }

    const tooltipStateChanged = !isEqual(tooltip, oldState.tooltip);

    dispatch('@events/tooltip/update', tooltip);

    return {
      tooltip,
      ...(tooltipStateChanged && { __dataCapsule: Date.now() }),
    };
  });

  on('app/setInitialData', (state, { widgetSession }) => {
    if (!widgetSession?.widgetData) {
      return;
    }
    return {
      tooltip: {
        ...state.tooltip,
        ...widgetSession?.widgetData.tooltip,
      },
    };
  });
};
