import { createStoreon } from 'storeon';
import { storeonDevtools, storeonLogger } from 'storeon/devtools';

import { appModule, AppState, AppEvents } from './modules/app';
import { chatbotModule, ChatbotState, ChatbotEvents } from './modules/chatbot';
import { widgetModule, WidgetState, WidgetEvents } from './modules/widget';
import { tooltipModule, TooltipReducer as TooltipState, TooltipEvents } from './modules/tooltip';
import { contextModule, ContextState, ContextEvents } from './modules/context';

export type ItemPosition = {
  left: number;
  top: number;
  bottom: number;
  right: number;
};

export type State = AppState & ChatbotState & WidgetState & TooltipState & ContextState;

export type Events = AppEvents & ChatbotEvents & WidgetEvents & TooltipEvents & ContextEvents;

type TKey = keyof State;
type TConnectCallback = (state: State) => void;
type TConnect = (key: any, cb: TConnectCallback) => () => void;

interface ISubscriber {
  key: TKey;
  cb: TConnectCallback;
}

let subscribers: ISubscriber[] = [];

export const store = createStoreon<State, Events>([
  appModule,
  chatbotModule,
  tooltipModule,
  widgetModule,
  contextModule,
  ...(process.env.NODE_ENV === 'development' ? [storeonDevtools, storeonLogger] : []),
]);

const { on, get, dispatch } = store;

const connect: TConnect = (key, cb) => {
  subscribers.push({ key, cb });

  return () => {
    subscribers = subscribers.filter((s) => s.cb !== cb);
  };
};

const bind: TConnect = (key, cb) => {
  requestAnimationFrame(() => {
    cb(get());
  });

  return connect(key, cb);
};

on('@changed', (state, diff) => {
  subscribers.forEach((subscriber) => {
    if (subscriber.key in diff) {
      subscriber.cb(state);
    }
  });
});

export { get as getState, dispatch, connect, bind, on as onStoreEvent };
